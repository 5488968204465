"use es6";

import React, {
  useCallback,
  useRef,
  useMemo,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { ANIMATION_DURATIONS } from "../style/constants";
import FinstaPhoto from "./FinstaPhoto";

const StyledPhotoGrid = styled.div`
  align-items: center;
  animation: slideup ${ANIMATION_DURATIONS.SLOW}s ease;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const PHOTOS_TO_SHOW_INCREMENT = 15;

const FinstaGrid = ({ onClickFinstaPhoto, photos }) => {
  const totalNumPhotos = photos.length;
  const photoGridRef = useRef(null);
  const [numPhotosShowing, setNumPhotosShowing] = useState(
    PHOTOS_TO_SHOW_INCREMENT
  );

  const handleSetScrollPos = useCallback(() => {
    if (totalNumPhotos === numPhotosShowing) {
      return null;
    }

    if (photoGridRef) {
      const scrollY = window.innerHeight + window.scrollY;
      const photoGridHeight =
        photoGridRef.current.offsetHeight + photoGridRef.current.offsetTop;

      const isAtBottomOfPhotoGrid = scrollY >= photoGridHeight;

      if (!isAtBottomOfPhotoGrid) {
        return null;
      }

      // show the rest of the photos
      if (numPhotosShowing + PHOTOS_TO_SHOW_INCREMENT > totalNumPhotos) {
        return setNumPhotosShowing(totalNumPhotos);
      }

      // show some more photos
      return setNumPhotosShowing(numPhotosShowing + PHOTOS_TO_SHOW_INCREMENT);
    }
  }, [numPhotosShowing, totalNumPhotos]);

  useEffect(() => {
    window.addEventListener("scroll", handleSetScrollPos);

    return function cleanup() {
      window.removeEventListener("scroll", handleSetScrollPos);
    };
  }, [handleSetScrollPos]);

  const renderPhoto = useCallback(
    (src, idx) => {
      return (
        <FinstaPhoto
          imgIdx={idx}
          key={`finsta-${src}`}
          onClickFinstaPhoto={onClickFinstaPhoto}
          src={src}
        />
      );
    },
    [onClickFinstaPhoto]
  );

  const photosToRender = useMemo(() => {
    return photos.slice(0, numPhotosShowing);
  }, [numPhotosShowing, photos]);

  const renderedPhotos = useMemo(() => {
    return photosToRender.map(renderPhoto);
  }, [photosToRender, renderPhoto]);

  return <StyledPhotoGrid ref={photoGridRef}>{renderedPhotos}</StyledPhotoGrid>;
};

export default FinstaGrid;
