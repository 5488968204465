"use es6";

import React, { useCallback, useMemo, useState } from "react";
import CategorySubheading from "./CategorySubheading";
import styled from "styled-components";
import { ANIMATION_DURATIONS, COLORS, SCREEN_SIZES } from "../style/constants";
import { POSTS } from "../content/PostData";
import SecondaryPostPreview from "./SecondaryPostPreview";
import FilteredBackgroundImage from "./FilteredBackgroundImage";
import PostLink from "./PostLink";
import PostPreviewTitle from "./PostPreviewTitle";
import lilarrow from "../assets/lilarrow.svg";

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ useTopPadding }) => useTopPadding && "72px"};

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    padding-top: ${({ useTopPadding }) => useTopPadding && "13%"};
  }
`;

const FeaturedPhoto = styled(FilteredBackgroundImage)`
  animation: fadein ${ANIMATION_DURATIONS.SLOW}s ease;
  height: 60vh;
  width: 100vw;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    height: 75vh;
  }
`;

const PaddedContents = styled.div`
  padding-left: 120px;
  padding-right: 15px;
  padding-top: 100px;
  z-index: 1;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    padding-top: 250px;
  }
`;

const SecondaryPhotos = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    flex-direction: row;
  }
`;

const SeeMoreSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 45px 45px 120px 45px;
  justify-content: center;
  width: 100vw;
`;

const SeeMore = styled.div`
  color: ${COLORS.PINK};
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const Arrow = styled.img`
  height: 30px;
  padding-top: 6px;
  transform: rotate(270deg);
  user-select: none;
  width: 30px;
`;

const PaddedDiv = styled.div`
  height: 120px;
`;

const PostPreviewGrid = ({
  featuredPost,
  featuredPhotoUrl,
  maxPosts,
  posts,
  useTopPadding,
}) => {
  const [numberOfPostsToShow, setNumberOfPostsToShow] = useState(maxPosts);

  const restOfPosts = posts || [POSTS[1], POSTS[2]];
  const shouldShowSeeMoreButton = useMemo(() => {
    return !featuredPost && restOfPosts.length > numberOfPostsToShow;
  }, [featuredPost, restOfPosts.length, numberOfPostsToShow]);

  const postsToRender = useMemo(() => {
    return numberOfPostsToShow
      ? restOfPosts.slice(0, numberOfPostsToShow)
      : restOfPosts;
  }, [numberOfPostsToShow, restOfPosts]);

  const renderSecondaryPhoto = (post) => {
    return <SecondaryPostPreview post={post} key={post.route} />;
  };

  const renderSecondaryPosts = useCallback(() => {
    return postsToRender.map(renderSecondaryPhoto);
  }, [postsToRender]);

  const renderFeaturedPost = useCallback(() => {
    if (!featuredPost) {
      return null;
    }

    return (
      <PostLink to={`/posts/${featuredPost.route}`}>
        <FeaturedPhoto
          url={featuredPhotoUrl}
          backgroundPosition={featuredPost.backgroundPosition}
        >
          <PaddedContents>
            <PostPreviewTitle>{featuredPost.title}</PostPreviewTitle>
            <CategorySubheading
              category={featuredPost.category}
              subcategory={featuredPost.subcategory}
            />
          </PaddedContents>
        </FeaturedPhoto>
      </PostLink>
    );
  }, [featuredPost, featuredPhotoUrl]);

  const handleShowMorePosts = useCallback(() => {
    const totalPosts = restOfPosts.length;
    if (numberOfPostsToShow + maxPosts > totalPosts) {
      setNumberOfPostsToShow(totalPosts);
    }
    setNumberOfPostsToShow(numberOfPostsToShow + maxPosts);
  }, [maxPosts, numberOfPostsToShow, restOfPosts.length]);

  const renderSeeMoreButton = useCallback(() => {
    if (shouldShowSeeMoreButton) {
      return (
        <SeeMoreSection onClick={handleShowMorePosts}>
          <SeeMore>See More</SeeMore>
          <Arrow src={lilarrow} />
        </SeeMoreSection>
      );
    }
    return !featuredPost && <PaddedDiv />;
  }, [handleShowMorePosts, featuredPost, shouldShowSeeMoreButton]);

  return (
    <GridWrapper useTopPadding={useTopPadding}>
      {renderFeaturedPost()}
      <SecondaryPhotos>{renderSecondaryPosts()}</SecondaryPhotos>
      {renderSeeMoreButton()}
    </GridWrapper>
  );
};

export default PostPreviewGrid;
