"use es6";

// stairs
import feature from "./feature.jpg";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpg";
import img5 from "./5.jpg";
import img6 from "./6.jpg";
import img7 from "./7.jpg";
import img8_actually3 from "./8.jpg";
import img9 from "./9.jpg";
import img10 from "./10.jpg";
import img11 from "./11.jpg";
import img12 from "./12.jpg";
import img13 from "./13.jpg";
import img14 from "./14.jpg";
import img15 from "./15.jpg";

export const post = {
  backgroundImageUrl: feature,
  backgroundPosition: "center -500px",
  body: `Repainting stairs is a great way to refresh the look of a home and increase its overall value. The process involves removing the old paint, sanding the surface, and applying a fresh coat of paint or stain. It's important to use a paint that is suitable for high-traffic areas, such as stairs, and to choose a color that complements the existing décor. Before starting the project, it's also essential to protect the surrounding area with drop cloths and masking tape to prevent drips and spills.
  `,
  body2: `The actual repainting process can be time-consuming, but the end result is worth it. It's important to follow the manufacturer's instructions for the paint or stain being used, including the recommended number of coats and drying times. For a professional-looking finish, it's recommended to use a paintbrush for edges and corners and a roller for the flat surfaces. Once the paint has fully dried, it's important to remove any masking tape and touch up any missed spots. With proper preparation and attention to detail, repainting stairs can be a successful DIY project or a worthwhile investment for a professional painter.<br/><br/><i>This blog post was written by ChatGPT</i>`,
  category: "Living",
  date: "April 19, 2021",
  images: [
    img1,
    img2,
    img8_actually3,
    img3,
    img4,
    img5,
    img6,
    img7,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
  ],
  route: "stairs",
  subcategory: "Tedious House Projects",
  title: "A Stair is Born",
};
