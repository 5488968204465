"use es6";

import styled from "styled-components";
import { ANIMATION_DURATIONS, COLORS, FILTERS } from "../style/constants";

const FilteredBackgroundImage = styled.div`
  align-items: center;
  display: flex;
  position: relative;

  ::before {
    background-image: url(${({ url }) => url});
    background-color: ${COLORS.LIGHT_GREEN};
    background-position: ${({ backgroundPosition }) =>
      backgroundPosition || "center"};
    background-size: cover;
    content: "";
    filter: ${FILTERS.DARKER};
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: filter ${ANIMATION_DURATIONS.MEDIUM}s ease;
    width: 100%;
  }

  &:hover {
    ::before {
      filter: ${FILTERS.DARKEST};
    }
  }
`;

export default FilteredBackgroundImage;
