"use es6";
import React, { useState } from "react";
import styled from "styled-components";
import { ANIMATION_DURATIONS, COLORS } from "../style/constants";
import LoadingIndicator from "../components/LoadingIndicator";
import SignupForm from "../components/SignupForm";
import Footer from "../components/Footer";
import usePageTitle from "../components/usePageTitle";

const SignupContainer = styled.div`
  align-items: center;
  animation: fadein ${ANIMATION_DURATIONS.FAST}s ease;
  flex-direction: column;
  font-family: "Playfair Display", serif;
  font-size: 24px;
  height: 100vh;
  display: flex;
  justify-content: center;
  width: 100vw;

  ${({ shouldHide }) => shouldHide && `display: none;`}
`;

const StyledHeading = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-size: 24px;
  margin-bottom: 18px;
`;

const StyledSubheading = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-size: 15px;
  margin-bottom: 50px;
`;

const Signup = () => {
  usePageTitle("Signup");
  const [isLoading, setIsLoading] = useState(true);
  setTimeout(setIsLoading.bind(this, false), 1000);

  return (
    <React.Fragment>
      {isLoading && <LoadingIndicator />}
      <SignupContainer shouldHide={isLoading}>
        <StyledHeading>Let's get together, yeah yeah yeah</StyledHeading>
        <StyledSubheading>
          Sign up to receive email updates about new posts
        </StyledSubheading>
        <SignupForm shouldHide={isLoading} />
      </SignupContainer>
      {!isLoading && <Footer />}
    </React.Fragment>
  );
};

export default Signup;
