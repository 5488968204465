"use es6";

import { post as kitchen } from "./22-kitchen/post.js";
import { post as noinsta } from "./23-no-insta/post.js";
import { post as recap2022 } from "./21-recap-2022/post.js";
import { post as walden } from "./18a-walden/post.js";
import { post as nantucket } from "./18-nantucket/post.js";
import { post as recap2021 } from "./17-recap-2021/post.js";
import { post as stairs } from "./16-stairs/post.js";
import { post as miami } from "./15-miami/post.js";
import { post as chasing_snow } from "./14-chasing-snow/post.js";
import { post as trudy_closet } from "./13-trudy-closet/post.js";
import { post as trudy_snow } from "./12-trudy-snow/post.js";
import { post as portland } from "./11-portland/post.js";
import { post as jonesport } from "./10-jonesport/post.js";
import { post as wedding_website } from "./9-wedding-website/post.js";
import { post as moosilauke } from "./8-moosilauke/post.js";
import { post as zermatt } from "./7-zermatt/post.js";
import { post as engagement } from "./6-engagement/post.js";
import { post as house_plants } from "./5-plants/post.js";
import { post as making_the_gallery } from "./4-gallery/post.js";
import { post as roofdeck } from "./3-roofdeck/post.js";
import { post as raleigh } from "./2-raleigh/post.js";
import { post as bad_good_cards } from "./1-cards/post.js";

/**
 *
 * REMINDER: Don't forget to compress pictures
 *
 */

export const POSTS = [
  kitchen, // July 8, 2023
  noinsta, // June 29, 2023
  recap2022, // December 31, 2022
  walden, // July 2021
  nantucket, // Summer 2021
  recap2021, // December 31, 2021
  stairs, // April 19, 2021
  miami, // March 18, 2021
  chasing_snow, // February 18, 2021
  trudy_closet, // Nov 14, 2020
  trudy_snow, // Oct 30, 2020
  portland, // Oct 17, 2020
  jonesport, // Oct 10, 2020
  zermatt, // Feb 29, 2020
  engagement, // May 09, 2020
  house_plants, // Apr 25, 2020
  moosilauke, // May 22, 2020
  wedding_website, // Jan 7, 2020
  making_the_gallery, // Mar 14, 2020
  roofdeck, // Feb 22, 2020
  raleigh, // Jan 4, 2020
  bad_good_cards, // Jan 1, 2020
];

/**
 * Other ideas:
 *
 * Wherever:
 *  Portland, Maine
 *  Camping in Acadia
 *  Big Sur
 *  Portland, Oregon
 *  Jonesport, ME
 *   Caymans
 *   Hawaii
 *
 *
 * Whatever
 *  wedding – Buying my dress, planning on Aisle Planner, Google sheets, picking vendors
 *  Ski touring setup
 *  Working out (orange theory, working from home, home gym)
 *  Engagement / wedding planning
 *  Deleting Instagram
 *
 * Living:
 *  (1) Buying a house
 *  (2) Cold brew
 *  (3) Trudy - bathroom
 *  (4) Trudy - mudroom / tiling
 *  (5) Trudy - painting radiator covers
 *  (6) Trudy - grass/yard
 *  (6) Trudy - snow
 *   Nursery
 *   Attic
 *   backyard glow ups
 *
 * Code – should this be renamed tech?
 *  (1) Netlify
 *  (2) Making this website
 *  (3) our wedding website
 *  Jessie's website
 *  Learning to use HubSpot
 *  Switching to backend
 *  Becoming an engineer?
 *  Learning react? Something about VSCode? bash profile?
 *  The sticky menu (scrolling up)
 *  how to optimize it (image compression, reusing components, tracking)
 *  ChatGPT
 */
