"use es6";

// jonesport
import feature from "./feature.JPG";
import img1 from "./1.JPG";
import img2 from "./2.JPG";
import img3 from "./3.JPG";
import img4 from "./4.JPG";
import img5 from "./5.JPG";
import img6 from "./6.JPG";
import img7 from "./7.JPG";
import img8 from "./8.JPG";
import img9 from "./9.JPG";
import img10 from "./10.JPG";
import img11 from "./11.JPG";
import img12 from "./12.JPG";
import img13 from "./13.JPG";
import img14 from "./14.JPG";
import img15 from "./15.JPG";
import img16 from "./16.JPG";
import img17 from "./17.JPG";
import img18 from "./18.JPG";
import img19 from "./19.JPG";
import img20 from "./20.JPG";
import img21 from "./21.JPG";
import img22 from "./22.JPG";
import img23 from "./23.JPG";
import img24 from "./24.JPG";
import img25 from "./25.JPG";
import img26 from "./26.JPG";
import img27 from "./27.JPG";
import img28 from "./28.JPG";
import img29 from "./29.JPG";
import img30 from "./30.JPG";
import img31 from "./31.JPG";
import img32 from "./32.JPG";
import img33 from "./33.JPG";
import img34 from "./34.JPG";
import img35 from "./35.JPG";
import img36 from "./36.JPG";
import img37 from "./37.JPG";
import img38 from "./38.JPG";
import img39 from "./39.JPG";
import img40 from "./40.JPG";
import img41 from "./41.JPG";
import img42 from "./42.JPG";
import img43 from "./43.JPG";
import img44 from "./44.JPG";
import img45 from "./45.JPG";
import img46 from "./46.JPG";
import img47 from "./47.JPG";
import img48 from "./48.JPG";
import img49 from "./49.JPG";
import img50 from "./50.JPG";

export const post = {
  backgroundImageUrl: feature,
  backgroundPositionMobile: "center left",
  body: `<p>This year is so different than any other year before it. That is wildly generic and true for all years, but this year was so much further from my expectations and put me in situations I have never before handled. Let's recap: planning a wedding, postponing a wedding, buying a house, covering the expense of our first major repair in the house (pour one out for the water heater), constantly wearing a mask, deleting my Instagram and Facebook (!), and the list goes on. It's been a weird year, but a year for introspection and growth. It also has <i>not</i> been a year for vacation, which is why we planned this relaxing, remote getaway in peak Fall this year.</p>`,
  body2: `<p>Jonesport, Maine is a quick 5.5 hours north of Boston. It's a tiny, quiet town surrounded by the beautiful, rocky coast. It was the perfect destination for our week away. We bought local seafood, cooked every meal, spent the mornings reading, and evenings winding down by the fire. We celebrated an engagement, birthdays, and finally having time away from work and with one another. We somehow also managed the chaos of 3 Doodle puppies running around the whole time. This year is so different from any other year before it, but I will remember this trip as a highlight. +1 for 2020.</p>`,
  category: "Travel",
  date: "October 10, 2020",
  images: [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img49,
    img50,
  ],
  route: "jonesport",
  subcategory: "Lobster",
  title: "Jonesport, Maine",
};
