"use es6";
import React from "react";
import styled from "styled-components";
import me from "../assets/me.jpg";
import { ANIMATION_DURATIONS, COLORS, SCREEN_SIZES } from "../style/constants";
import SocialIcons from "../components/SocialIcons";
import Footer from "../components/Footer";
import isLoading from "../helpers/isLoading";
import LoadingIndicator from "../components/LoadingIndicator";
import useLazyImage from "../components/useLazyImage";
import SignupButton from "../components/SignupButton";
import usePageTitle from "../components/usePageTitle";

const AboutWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  padding-top: 80px;
  overflow: hidden;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    padding-top: 120px;
  }

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    align-items: unset;
    justify-content: center;
    flex-direction: row;
  }
`;

const Portrait = styled.div`
  animation: slideright ${ANIMATION_DURATIONS.SLOW}s ease;
  background-image: url(${({ src }) => src});
  background-position: bottom;
  background-size: cover;
  height: 400px;
  width: 90vw;
  min-width: 400px;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    height: 720px;
    width: 650px;
  }
`;

const FloatingPinkSection = styled.div`
  animation: slideleft ${ANIMATION_DURATIONS.SLOW}s ease;
  background-color: ${COLORS.LIGHT_PINK};
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-left: 30px;
  margin-top: -30px;
  padding: 30px;
  z-index: 1;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    margin-left: -5vw;
    margin-top: 10vh;
    padding: 45px;
  }

  @media screen and (min-width: ${SCREEN_SIZES.LARGE}) {
    margin-left: -5vw;
    padding: 60px;
  }
`;

const Description = styled.div`
  font-family: "Playfair Display", serif;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 30px;
  max-width: 425px;
  padding-left: 21px;

  :first-letter {
    float: left;
    font-size: 45px;
    line-height: 0.9;
    margin-top: 8px;
    padding-right: 15px;
  }

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    :first-letter {
      font-size: 75px;
      margin-top: 0;
    }
  }
`;

const StyledLink = styled.a`
  color: ${COLORS.PINK};
  font-weight: 600;
  padding-left: 2px;
  text-decoration: none;

  &:hover {
    color: ${COLORS.DARK_PINK};
  }
`;

const About = () => {
  const portraitSrc = useLazyImage(me);
  usePageTitle("About");

  if (isLoading(portraitSrc)) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <AboutWrapper>
        <Portrait src={portraitSrc} />
        <FloatingPinkSection>
          <Description>
            Hello, I'm Anna Flynt. I'm a Boston-based software engineer and
            hobby enthusiast. This is my collection of inspiration, projects,
            and ideas from everywhere. I built this website with design help
            from my talented husband (and artist){" "}
            <StyledLink href="https://www.lukeflynt.com/" target="_blank">
              Luke Flynt
            </StyledLink>{" "}
            <br />
            <br />
            Questions? Comments? Want to collaborate? Send me an email{" "}
            <StyledLink>hello@whereverist.com</StyledLink>
          </Description>
          <SocialIcons
            disableAnimation={true}
            useColumn={true}
            useLabels={true}
          />
          <SignupButton color={COLORS.BLACK} disableAnimation={true} />
        </FloatingPinkSection>
      </AboutWrapper>
      <Footer />
    </>
  );
};

export default About;
