"use es6";
import React from "react";
import styled from "styled-components";
import { COLORS, ANIMATION_DURATIONS } from "../style/constants";
import MenuItem from "../components/MenuItem";
import usePageTitle from "../components/usePageTitle";

const SignupCompleteContainer = styled.div`
  align-items: center;
  animation: fadein ${ANIMATION_DURATIONS.FAST}s ease;
  background: ${COLORS.BLUE};
  color: ${COLORS.PINK};
  flex-direction: column;
  font-family: "Playfair Display", serif;
  font-size: 24px;
  height: 100vh;
  display: flex;
  justify-content: center;
  width: 100vw;
`;

const BackToHome = styled.div`
  background-color: ${COLORS.PINK};
  border-radius: 3px;
  cursor: pointer;
  margin-top: 48px;
  padding: 8px 12px;
  transition: box-shadow ${ANIMATION_DURATIONS.FAST}s ease;

  &:hover {
    box-shadow: 0px 10px 30px ${COLORS.OPAQUE_BLACK};
  }
`;

const SignupComplete = () => {
  usePageTitle("Yahoooo");

  return (
    <SignupCompleteContainer
      shouldUseFullHeight={true}
      shouldAlignCenter={true}
    >
      <div>Hooray! Thanks for signing up.</div>
      <BackToHome>
        <MenuItem
          color={COLORS.WHITE}
          fontSize="15px"
          name="Back to home page"
          route=""
        />
      </BackToHome>
    </SignupCompleteContainer>
  );
};

export default SignupComplete;
