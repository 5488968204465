"use es6";

import React from "react";
import styled from "styled-components";
import Microcopy from "./Microcopy";
import GalleryIcon from "./GalleryIcon";
import { SCREEN_SIZES, COLORS } from "../style/constants";

const ViewGalleryLink = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  max-width: 126px;
  margin: ${({ smallScreenOnly }) =>
    smallScreenOnly ? "60px 0 30px 0" : "45px 30px 30px 30px"};
  width: 100%;

  ${({ shouldReduceSpacing }) =>
    shouldReduceSpacing &&
    `
  margin: 30px;
  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    margin: 12px;
    }
  `}

  ${({ largeScreenOnly }) =>
    largeScreenOnly &&
    `
    @media screen and (min-width: ${SCREEN_SIZES.LARGE}) {
      display: none;
    }
  `};

  ${({ useBackground }) =>
    useBackground &&
    `
    background-color: ${COLORS.LIGHT_PINK};
    margin: 0 0 60px 0;
    padding: 45px;
  `};

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    display: ${({ smallScreenOnly }) => smallScreenOnly && "none"};
  }
`;

const StyledMicrocopy = styled(Microcopy)`
  flex-shrink: 0;
  padding-right: 6px;
`;

const ViewGallery = ({
  buttonText,
  largeScreenOnly,
  numberOfImages,
  onLinkClick,
  shouldReduceSpacing,
  smallScreenOnly,
  useBackground,
}) => {
  const text = buttonText || "View gallery";

  return (
    <ViewGalleryLink
      onClick={onLinkClick}
      largeScreenOnly={largeScreenOnly}
      shouldReduceSpacing={shouldReduceSpacing}
      smallScreenOnly={smallScreenOnly}
      useBackground={useBackground}
    >
      <StyledMicrocopy>{`${text} (${numberOfImages})`}</StyledMicrocopy>
      <GalleryIcon />
    </ViewGalleryLink>
  );
};

export default ViewGallery;
