"use es6";

import React, { useState, useCallback } from "react";
import styled from "styled-components";
import {
  ANIMATION_DURATIONS,
  COLORS,
  FILTERS,
  SCREEN_SIZES,
} from "../style/constants";
import useLazyImage from "../components/useLazyImage";
import placeholder from "../assets/placeholder.jpg";
import PostFooter from "../components/PostFooter";
import Footer from "../components/Footer";
import PostBody from "../components/PostBody";
import isLoading from "../helpers/isLoading";
import LoadingIndicator from "../components/LoadingIndicator";
import PostTitle from "../components/PostTitle";
import FloatingPostTitle from "../components/FloatingPostTitle";
import Gallery from "../components/Gallery";
import ViewGallery from "../components/ViewGallery";
import usePageTitle from "../components/usePageTitle";

const POST_BODY_MAX_WIDTH = 700;

const StyledPost = styled.section`
  animation: ${({ shouldAnimate }) =>
    shouldAnimate && `fadein ${ANIMATION_DURATIONS.SLOW}s ease`};
  color: ${COLORS.GREEN};
  overflow-y: ${({ shouldDisableScroll }) => shouldDisableScroll && "hidden"};
  position: relative;
`;

const HeaderImage = styled.div`
  background-image: url(${({ backgroundUrl }) => backgroundUrl});
  background-size: cover;
  background-position: ${({ shouldPositionCenter }) =>
    shouldPositionCenter ? "center" : "right"};
  filter: ${FILTERS.DARKER};
  height: 50vh;
  position: relative;
  width: 100vw;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    background-position: ${({ backgroundPosition, shouldPositionCenter }) => {
      if (shouldPositionCenter) {
        return "center";
      }
      return backgroundPosition || "right";
    }};
  }
`;

const PostBodyContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 45px 30px;
  width: 100vw;

  @media screen and (min-width: ${SCREEN_SIZES.SMALL}) {
    padding: 0 45px 45px 45px;
  }

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    align-items: flex-start;
    padding: 0 120px 45px 120px;
  }
`;

const ViewGalleryFooterWrapper = styled.div`
  animation: ${({ shouldAnimate }) =>
    shouldAnimate && `slideup ${ANIMATION_DURATIONS.SLOW}s ease`};
  display: flex;
  width: 100vw;
  justify-content: center;
`;

const ViewGalleryHeaderWrapper = styled.div`
  animation: ${({ shouldAnimate }) =>
    shouldAnimate && `slideup ${ANIMATION_DURATIONS.SLOW}s ease`};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Post = ({ currentPostIdx, post }) => {
  usePageTitle(post.title);

  // hack to get the header image and title box to re-animate
  // on navigating to the previous/next post
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const [isShowingGallery, setIsShowingGallery] = useState(false);
  const [defaultPostIdx, setDefaultPostIdx] = useState(0);

  const handleEndAnimation = useCallback(() => {
    setShouldAnimate(false);
  }, []);

  const handleResetAnimation = useCallback(() => {
    setShouldAnimate(true);
  }, []);

  const handleToggleGallery = useCallback(
    (postIdx) => {
      setIsShowingGallery(!isShowingGallery);

      // ensure it's a number... otherwise it's the event
      if (!isNaN(postIdx)) {
        setDefaultPostIdx(postIdx);
      }

      if (!isShowingGallery) {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = "fixed";
      } else {
        setDefaultPostIdx(0);
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }
    },
    [isShowingGallery]
  );

  const headerImg = useLazyImage(post.backgroundImageUrl);
  const img1 = useLazyImage(post.images[0]);
  const img2 = useLazyImage(post.images[1]);
  const img3 = useLazyImage(post.images[2] || post.backgroundImageUrl);

  const galleryImages = [...post.images, post.backgroundImageUrl];

  const renderFooter = useCallback(() => {
    // forcing the footer is only used for the gallery post
    const shouldRenderViewGalleryFooter =
      post.disableImages && !post.forceViewGalleryFooter;
    const ViewGalleryFooterButton = shouldRenderViewGalleryFooter && (
      <ViewGalleryFooterWrapper shouldAnimate={shouldAnimate}>
        <ViewGallery
          largeScreenOnly={post.forceViewGalleryFooter ? false : true}
          numberOfImages={galleryImages.length}
          onLinkClick={handleToggleGallery}
          useBackground={true}
        />
      </ViewGalleryFooterWrapper>
    );

    return (
      <>
        {ViewGalleryFooterButton}
        <PostFooter
          currentPostIdx={currentPostIdx}
          onNavigate={handleResetAnimation}
        />
      </>
    );
  }, [
    currentPostIdx,
    handleResetAnimation,
    handleToggleGallery,
    galleryImages.length,
    post,
    shouldAnimate,
  ]);

  const renderBody = useCallback(() => {
    const ViewGalleryHeaderButton = !post.disableImages && (
      <ViewGalleryHeaderWrapper shouldAnimate={shouldAnimate}>
        <ViewGallery
          smallScreenOnly={true}
          onLinkClick={handleToggleGallery}
          numberOfImages={galleryImages.length}
        />
      </ViewGalleryHeaderWrapper>
    );

    return (
      <PostBodyContainer>
        <PostTitle
          onAnimationEnd={handleEndAnimation}
          post={post}
          shouldAnimate={shouldAnimate}
        />
        {ViewGalleryHeaderButton}
        <PostBody
          featImages={post.disableImages ? undefined : [img1, img2, img3]}
          post={post}
          maxWidth={POST_BODY_MAX_WIDTH}
          numberOfImages={galleryImages.length}
          onPostImageClick={handleToggleGallery}
          shouldAnimate={shouldAnimate}
        />
        <FloatingPostTitle
          numberOfImages={galleryImages.length}
          onGalleryLinkClick={handleToggleGallery}
          post={post}
          postBodyMaxWidth={POST_BODY_MAX_WIDTH}
        />
      </PostBodyContainer>
    );
  }, [
    img1,
    img2,
    img3,
    galleryImages.length,
    handleEndAnimation,
    handleToggleGallery,
    post,
    shouldAnimate,
  ]);

  if (post.disableImages) {
    if (isLoading(headerImg)) {
      return <LoadingIndicator />;
    }
  } else {
    if ([headerImg, img1, img2, img3].some(isLoading)) {
      return <LoadingIndicator />;
    }
  }

  // TODO ANNA: grid view for gallery
  return (
    <>
      <StyledPost
        shouldAnimate={shouldAnimate}
        onAnimationEnd={handleEndAnimation}
        shouldDisableScroll={isShowingGallery}
      >
        {isShowingGallery && (
          <Gallery
            defaultPostIdx={defaultPostIdx}
            featuredImageBackgroundPosition={post.backgroundPositionMobile}
            images={galleryImages}
            onToggleGallery={handleToggleGallery}
          />
        )}
        <HeaderImage
          backgroundPosition={post.backgroundPosition}
          backgroundUrl={headerImg || placeholder}
          shouldPositionCenter={!headerImg}
        />
        {renderBody()}
        {renderFooter()}
      </StyledPost>
      <Footer />
    </>
  );
};

export default Post;
