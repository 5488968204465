"use es6";

// walden
import engagement103 from "./annaluke_engagement-103-min.jpg";
import engagement114 from "./annaluke_engagement-114-min.jpg";
import engagement115 from "./annaluke_engagement-115-min.jpg";
import engagement120 from "./annaluke_engagement-120-min.jpg";
import engagement121 from "./annaluke_engagement-121-min.jpg";
import engagement134 from "./annaluke_engagement-134-min.jpg";
import engagement136 from "./annaluke_engagement-136-min.jpg";
import engagement15 from "./annaluke_engagement-150-min.jpg";
import engagement17 from "./annaluke_engagement-17-min.jpg";
import engagement21 from "./annaluke_engagement-21-min.jpg";
import feature1 from "./annaluke_engagement-23-min.jpg";
import engagement3 from "./annaluke_engagement-3-min.jpg";
import engagement39 from "./annaluke_engagement-39-min.jpg";
import engagement47 from "./annaluke_engagement-47-min.jpg";
import engagement49 from "./annaluke_engagement-49-min.jpg";
import engagement53 from "./annaluke_engagement-53-min.jpg";
import engagement55 from "./annaluke_engagement-55-min.jpg";
import engagement62 from "./annaluke_engagement-62-min.jpg";
import engagement79 from "./annaluke_engagement-79-min.jpg";
import engagement99 from "./annaluke_engagement-99-min.jpg";
import feature from "./annaluke_engagement-113-min.jpg";
import engagement149 from "./annaluke_engagement-149-min.jpg";

export const post = {
  backgroundImageUrl: feature,
  body: `I was seated by the shore of a small pond, about a mile and a half south of the village of Concord
  and somewhat higher than it, in the midst of an extensive wood between that town and known to
  fame, Concord Battle Ground; but I was so low in the woods that the opposite shore, half a mile
  off, like the rest, covered with wood, was my most distant horizon. For the first week, whenever
  I looked out on the pond it impressed me like a tarn high up on the side of a mountain, its bottom
  far above the surface of other lakes, and, as the sun arose, I saw it throwing off its nightly
  clothing of mist, and here and there by degrees, while the mists, like ghosts, were stealthily
  withdrawing in every direction into the woods, as at the breaking up of some nocturnal
  conventicler. The very dew seemed to hang upon the trees later into the day than usual, as on the
  sides of mountains...`,
  body2: `I went to the woods because I wished to live deliberately, to front only the essential facts of life,
  and see if I could not learn what it had to teach, and not, when I came to die, discover that I had
  not lived. I did not wish to live what was no life, living is so dear; nor did I wish to practice
  resignation, unless it was quite necessary. I wanted to live deep and suck out all the marrow of
  life, to live so sturdily and Spartan-like as to put to rout all that was not life, to cut a broad swath
  and shave close, to drive life into a corner, and reduce it to its lowest terms, and, if it proved to
  be mean, why then to get the whole and genuine meanness of it, and publish its meanness to the
  world; or if it were sublime, to know it by experience, and be able to give a true account of it in
  my next excursion. For most men, it appears to me, are in a strange uncertainty about it, whether
  it is of the devil or of God, and have somewhat hastily concluded that is the chief end of man
  here to “glorify God and enjoy him forever.”
  <br/><br/>
  -  Henry David Thoreau, <i>Walden</i><br/><br/><br/>
  <p><i>Photos by the talented <a href="http://griggieri.com/" target="_blank" rel="noopener noreferrer">Gabby Riggieri</a></i></p>`,
  category: "Whatever",
  date: "July 29, 2021",
  images: [
    engagement99,
    engagement39,
    engagement17,
    engagement149,
    engagement103,
    feature1,
    engagement114,
    engagement121,
    engagement115,
    engagement120,
    engagement15,
    engagement21,
    engagement134,
    engagement3,
    engagement47,
    engagement49,
    engagement53,
    engagement136,
    engagement55,
    engagement62,
    engagement79,
  ],
  route: "walden",
  subcategory: "Pondering",
  title: "Walden",
};
