"use es6";
import React, { useEffect } from "react";
import styled from "styled-components";

const StyledHubSpotForm = styled.div`
  ${({ shouldHide }) => shouldHide && `display: none;`}
`;

const SignupForm = ({ shouldHide }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "3890674",
          formId: "e89332d6-32c6-4c0a-b839-f88e01d718ab",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return <StyledHubSpotForm id="hubspotForm"></StyledHubSpotForm>;
};

export default SignupForm;
