"use es6";

// portland
import feature from "./feature.jpeg";
import img1 from "./1.jpeg";
import img2 from "./2.jpeg";
import img3 from "./3.jpeg";
import img4 from "./4.jpeg";
import img5 from "./5.jpeg";
import img6 from "./6.jpeg";
import img7 from "./7.jpeg";
import img8 from "./8.jpeg";
import img9 from "./9.jpeg";
import img10 from "./10.jpeg";
import img11 from "./11.jpeg";
import img12 from "./12.jpeg";
import img13 from "./13.jpeg";
import img14 from "./14.jpeg";
import img15 from "./15.jpeg";
import img16 from "./16.jpeg";
import img17 from "./17.jpeg";
import img18 from "./18.jpeg";


export const post = {
  backgroundImageUrl: feature,
  body: `<p>Portland, Maine is a special place. Particularly in October, the crisp air, the colorful leaves, and the brick fascades make it a quintessential New England Fall experience. That experience is reinforced by the countless seafood joints and craft breweries. For these reasons among others, we chose Portland as our wedding destination. It's the perfect blend of scenic, lively, and restaurant-ridden. Despite our wedding being moved out a year, we still came into town for our would-be wedding day. It was so different than we'd originally planned, yet still so great. We created memories over lobsters, oysters, champagne, and Maine beer.
  </p>`,
  body2: `<p>Another great thing about Portland is it's proximity to Boston. In just two hours the following morning, we were back home and able to enjoy even more leaf peeping and quality time. There's something about Fall in this area that is so inviting. The feel and freshness of the cool air and the warmer colors everywhere. Each year at this time, I look forward to rediscovering all my cozy winter clothes that've been packed away since Spring. And now, as a new homeowner, I'm even excited to decorate the house and welcome trick-or-treaters. It's reassuring to know that the seasons changing is still a constant. Our Fall rituals look pretty much the same as last year, Portland is still a place we love, and now, for the second time, there's less than a year until we get married.
  </p>`,
  category: "Whatever",
  date: "October 17, 2020",
  images: [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
  ],
  route: "fall-isnt-cancelled",
  subcategory: "Love",
  title: "Fall Isn't Cancelled",
};
