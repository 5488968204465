"use es6";

import styled from "styled-components";
import { COLORS, SCREEN_SIZES } from "../style/constants";

const PostPreviewTitle = styled.div`
  color: ${COLORS.WHITE};
  font-family: "Playfair Display", serif;
  font-size: 30px;
  z-index: 1;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    font-size: 45px;
  }

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    font-size: 50px;
  }
`;

export default PostPreviewTitle;
