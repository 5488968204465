"use es6";

import React, { useMemo, useState, useCallback } from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import { ANIMATION_DURATIONS, SCREEN_SIZES, COLORS } from "../style/constants";
import SocialIcons from "./SocialIcons";
import { useLocation } from "react-router-dom";
import grayCloseX from "../assets/grayCloseX.svg";
import grayPancakeIcon from "../assets/grayPancakeIcon.svg";
import whitePancakeIcon from "../assets/whitePancakeIcon.svg";

const MenuAndSocialIcons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${({ isFooterMenu }) =>
    isFooterMenu ? "column" : "row-reverse"};
  flex-grow: 1;
  max-width: ${({ isFooterMenu }) => isFooterMenu && "1000px"};
  width: ${({ isFooterMenu }) => isFooterMenu && "100%"};

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    flex-direction: column;
  }

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

const MenuItemsWrapper = styled.div`
  display: ${({ isMenuOpen, isFooterMenu }) =>
    isMenuOpen || isFooterMenu ? "block" : "none"};
  margin: ${({ isFooterMenu }) => isFooterMenu && "48px 21px 21px 21px"};

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    `
    background-color: ${COLORS.LIGHT_PINK};
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 3;
    `}

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    background: none;
    display: block;
    flex-grow: 1;
    height: unset;
    margin-top: 48px;
    max-width: 400px;
    position: relative;
    padding-left: 15px;
    width: unset;
  }

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    height: 10vh;
  }
`;

const StyledMenuItems = styled.div`
  align-items: center;
  animation: fadein ${ANIMATION_DURATIONS.SLOW}s ease;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${({ isFooterMenu }) => (isFooterMenu ? "row" : "column")};
  flex-wrap: ${({ isFooterMenu }) => isFooterMenu && "wrap"};
  height: 100%;
  justify-content: center;
  padding-bottom: ${({ isFooterMenu }) => !isFooterMenu && "120px"};

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    animation: slideleft ${ANIMATION_DURATIONS.SLOW}s ease;
    flex-direction: row;
    flex-grow: 2;
    height: unset;
    justify-content: space-around;
    padding-bottom: 0;
  }
`;

const PancakeMenuButton = styled.img`
  width: 24px;
  animation: fadein ${ANIMATION_DURATIONS.SLOW}s ease;
  cursor: pointer;
  right: 0;
  margin: 24px;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    display: none;
  }
`;

const CloseButton = styled.img`
  cursor: pointer;
  display: ${({ isMenuOpen }) => (isMenuOpen ? "block" : "none")};
  margin: 24px;
  position: absolute;
  left: 0;
  width: 24px;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    display: none;
  }
`;

export const MENU_MAP = [
  {
    menuItemColor: COLORS.WHITE,
    name: "Home",
    route: "",
    mobileOnly: true,
  },
  {
    menuItemColor: COLORS.DARK_GRAY,
    name: "Whatever",
    route: "wherever/whatever",
  },
  {
    menuItemColor: COLORS.DARK_GRAY,
    name: "Travel",
    route: "wherever/travel",
  },
  {
    menuItemColor: COLORS.DARK_GRAY,
    name: "Living",
    route: "wherever/living",
  },
  {
    menuItemColor: COLORS.DARK_GRAY,
    name: "Code",
    route: "wherever/code",
  },
  {
    menuItemColor: COLORS.DARK_GRAY,
    name: "About",
    route: "about",
  },
];

const Menu = ({ color, isFooterMenu, onMobileMenuClose, onMobileMenuOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = useCallback(() => {
    if (!isMenuOpen) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = "fixed";
      onMobileMenuOpen();
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      onMobileMenuClose();
    }

    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen, onMobileMenuClose, onMobileMenuOpen]);

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
    onMobileMenuClose();
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }, [onMobileMenuClose]);

  const { pathname } = useLocation();

  const menuItemColor = useMemo(() => {
    const currentMenuItem = MENU_MAP.find(
      (menuItem) => `/${menuItem.route}` === pathname
    );

    if ("/kutchmeifyoucan" === pathname) {
      return COLORS.DARK_GRAY;
    }

    if (!currentMenuItem) {
      return undefined;
    }
    return currentMenuItem.menuItemColor;
  }, [pathname]);

  const MenuItems = useMemo(() => {
    return MENU_MAP.map((menuItem) => {
      return (
        <MenuItem
          color={color || menuItemColor}
          isCurrentRoute={pathname === `/${menuItem.route}`}
          isFooterMenu={isFooterMenu}
          key={menuItem.route}
          name={menuItem.name}
          mobileOnly={menuItem.mobileOnly}
          onMenuItemClick={handleCloseMenu}
          route={menuItem.route}
        />
      );
    });
  }, [color, handleCloseMenu, isFooterMenu, menuItemColor, pathname]);

  const renderPancakeMenuButton = useCallback(() => {
    if (isFooterMenu) {
      return null;
    }

    const buttonColor = color || menuItemColor;

    const src =
      buttonColor === COLORS.DARK_GRAY ? grayPancakeIcon : whitePancakeIcon;

    return (
      <PancakeMenuButton
        alt="Menu"
        src={src}
        onClick={handleToggleMenu}
        isFooterMenu={isFooterMenu}
      />
    );
  }, [color, menuItemColor, handleToggleMenu, isFooterMenu]);

  const renderCloseButton = useCallback(() => {
    if (isFooterMenu) {
      return null;
    }

    return (
      <CloseButton
        alt="Close"
        src={grayCloseX}
        isMenuOpen={isMenuOpen}
        onClick={handleCloseMenu}
      />
    );
  }, [handleCloseMenu, isFooterMenu, isMenuOpen]);

  const renderMenuItems = useCallback(() => {
    return (
      <StyledMenuItems isFooterMenu={isFooterMenu}>{MenuItems}</StyledMenuItems>
    );
  }, [MenuItems, isFooterMenu]);

  return (
    <MenuAndSocialIcons isFooterMenu={isFooterMenu}>
      <>
        {renderPancakeMenuButton()}
        <MenuItemsWrapper isMenuOpen={isMenuOpen} isFooterMenu={isFooterMenu}>
          {renderCloseButton()}
          {renderMenuItems()}
        </MenuItemsWrapper>
      </>
      <SocialIcons
        isMenuOpen={isMenuOpen}
        isFooterMenu={isFooterMenu}
        onClick={handleCloseMenu}
      />
    </MenuAndSocialIcons>
  );
};

Menu.defaultProps = {
  onMobileMenuClose: () => {},
  onMobileMenuOpen: () => {},
};

export default Menu;
