"use es6";

// no insta
import insta1 from "./insta-1-min.png";
import insta2 from "./insta-2-min.png";
import insta3 from "./insta-3-min.png";
import insta4 from "./insta-4-min.png";
import insta5 from "./insta-5-min.png";

export const post = {
  backgroundImageUrl: insta5,
  backgroundPosition: "center 100px",
  body: `
  <p>After Instagram I built a blog</p>
  <p>Coded away, played with my dog</p>
  <p>After Instagram, I stepped away</p>
  <p>From judging myself, keeping living at bay</p>
  <p>After Instagram, I learned some things</p>
  <p>Read some books, enjoyed some Springs</p>
  <p>Got married, texted friends,</p> 
  <p>and begun to see more of life again</p>
  <p>I got to spend less time with my phone,</p>
  <p>disconnected from friends and yet less alone</p>
  <p>I wrote, I watched, I swam, I danced</p>
  <p>I listened to stories rather than watched them</p>
  <p>I took wide photos rather than chopped them</p>
  `,
  body2: `<p>In Fall, in Winter, in Summer, in Spring</p>
  <p>It felt like a hole was filling right in</p>
  <p>Without my phone to evaporate time,</p>
  <p>I read many books, I wrote this rhyme</p>
  <p>Now and again, I miss the connection</p>
  <p>But I'm better off with more introspection</p>
  <p>I'm less stressed when there is an election</p>
  <p>I no longer see no 'Likes' like rejection</p>
  <p>I'm no longer chasing posed perfection</p>
  <p>For me, this is better. For others, it's not</p>
  <p>There are people and birthdays, names I've forgot</p>
  <p>Yes, I feel FOMO now and again</p>
  <p>But I have more time now then I did then</p>
  <br/>
  <br/>
  <i>It is estimated that I have saved 840 hours (35 days) that I would have otherwise spent on Instagram in the last 1,000 days since leaving Instagram.</i>
  `,
  category: "Whatever",
  date: "June 29, 2023",
  images: [insta1, insta2, insta3, insta4],
  route: "no-insta",
  subcategory: "Getting Time Back",
  title: "1,000 Days Off Instagram",
};
