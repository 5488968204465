"use es6";

// recap 2021
import luke from "./0D3A8FC8-9F0C-4CDF-ADF3-B2A3F0D4E6EE-min.jpg";
import feature from "./B9BFFB06-BA49-453D-8345-53D6A4518643-min.jpg";
import FullSizeRender from "./1.jpg";
import FullSizeRender2 from "./4.jpg";
import FullSizeRender3 from "./5.jpg";
import FullSizeRender4 from "./6.jpg";
import FullSizeRender5 from "./7.jpg";
import FullSizeRender6 from "./8.jpg";
import FullSizeRender7 from "./r.jpg";
import IMG_0357 from "./IMG_0357-min.JPG";
import IMG_0870 from "./IMG_0870-min.jpg";
import IMG_0911 from "./IMG_0911-min.jpg";
import leafblower from "./IMG_1186-min.jpg";
import IMG_1248 from "./IMG_1248-min.jpg";
import IMG_1251 from "./IMG_1251-min.jpg";
import IMG_1469 from "./IMG_1469-min.JPEG";
import IMG_5329 from "./IMG_5329-min.jpg";
import planting from "./IMG_5342-min.JPG";
import IMG_5348 from "./IMG_5348-min.jpg";
import IMG_5519 from "./IMG_5519-min.jpg";
import IMG_5840 from "./IMG_5840-min.jpg";
import raleigh from "./IMG_5892-min.jpg";
import IMG_5903 from "./IMG_5903-min.jpg";
import IMG_5919 from "./IMG_5919-min.JPG";
import feature1 from "./IMG_6035-min.JPG";
import IMG_6394 from "./IMG_6394-min.jpg";
import IMG_6417 from "./IMG_6417-min.jpg";
import IMG_6421 from "./IMG_6421-min.jpg";
import IMG_6519 from "./IMG_6519-min.JPG";
import IMG_6560 from "./IMG_6560-min.JPG";
import IMG_7270 from "./IMG_7270-min.jpg";
import IMG_7519 from "./IMG_7519-min.JPG";
import IMG_7578 from "./IMG_7578-min.JPG";
import IMG_7603 from "./IMG_7603-min.jpg";
import IMG_7614 from "./IMG_7614-min.jpg";
import IMG_7618 from "./IMG_7618-min.jpg";
import IMG_7621 from "./IMG_7621-min.jpg";
import IMG_7739 from "./IMG_7739-min.jpg";
import IMG_8760 from "./IMG_8760-min.jpg";
import IMG_9127 from "./IMG_9127-min.jpg";
import beaconhill from "./beaconhill-min.jpg";
import flowers from "./flowers-min.jpeg";

export const post = {
  backgroundImageUrl: feature,
  backgroundPosition: "center -1000px",
  body: `I'm writing this from the future. In February 2023. So, my memories and experiences since are going to cloud my understanding of 2021, it's feel, it's significance. I can tell you from now that it was extremely significant. It was turbulent, it was beautiful. It was life at it's fullest: a wedding, a pregnancy, so much travel. The grass really was greener (new sod will do that). Below is a collection of the photo-worthy times. The connection with friends, with my surroundings.
  `,
  body2: `What these photos don't show you are the thoughts and the times in between. We often are in between. And it's okay. This is wonderfully vague. I'm writing this as I read <i>The Dutch House</i>. The boy in the story shares his thoughts and opinions in a matter-of-fact way. As if everyone has thoughts like his. The truth is, he is profoundly perceptive. I am not claiming to be on his level, not even close. Though, it's giving me much to think about. As much was thought about in 2021. Looking back, it really was beautiful.`,
  category: "Whatever",
  date: "December 31, 2021",
  images: [
    luke,
    feature1,
    FullSizeRender4,
    FullSizeRender,
    FullSizeRender2,
    FullSizeRender3,
    FullSizeRender5,
    FullSizeRender6,
    FullSizeRender7,
    IMG_0357,
    IMG_0870,
    IMG_0911,
    leafblower,
    IMG_1248,
    IMG_1251,
    IMG_1469,
    IMG_5329,
    planting,
    IMG_5348,
    IMG_5519,
    IMG_5840,
    raleigh,
    IMG_5903,
    IMG_5919,
    IMG_6394,
    IMG_6417,
    IMG_6421,
    IMG_6519,
    IMG_6560,
    IMG_7270,
    IMG_7519,
    IMG_7578,
    IMG_7603,
    IMG_7614,
    IMG_7618,
    IMG_7621,
    IMG_7739,
    IMG_8760,
    IMG_9127,
    beaconhill,
    flowers,
  ],
  route: "2021",
  subcategory: "The Good Years",
  title: "2021: In Review",
};
