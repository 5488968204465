"use es6";

export const COLORS = {
  BLACK: "#000000",
  BLUE: "#385866",
  DARK_GRAY: "#5F5F5F",
  DARK_PINK: "#c78270",
  GRAY: "#dadada",
  GREEN: "#23312f",
  LIGHT_BLUE: "#d7dfe0",
  LIGHT_BLUE_SUMMER: "#afcae6",
  LIGHT_GREEN: "#829490",
  LIGHT_PINK: "#fef2ed",
  MEDIUM_BLUE: "#b0c2c6",
  NAVY: "#1e252d",
  OPAQUE_BLACK: "rgba(0, 0, 0, 0.25)",
  OPAQUE_WHITE: "rgba(255, 255, 255, 0.9)",
  PINK: "#e19582",
  WHITE: "#ffffff",
};

export const CODE_COLORS = {
  BLUE: "#47a8c7",
  DARK_GRAY: "#2b2727",
  LIGHT_BLUE: "#add8e6",
  LIGHT_GREEN: "#90ee90",
  LIGHT_YELLOW: "#ffffe0",
  ORANGE: "#c59783",
  PURPLE: "#e67ace",
  TEAL: "#3fe2ae",
};

export const SCREEN_SIZES = {
  SMALL: "475px",
  SMALLISH: "615px", // mobile breakpoint
  MEDIUM: "800px",
  LARGE: "1200px",
};

export const ANIMATION_DURATIONS = {
  SLOW: 1.25,
  SLOWISH: 0.75,
  MEDIUM: 0.5,
  FAST: 0.35,
};

export const FILTERS = {
  DARKER: "grayscale(20%) brightness(0.7);",
  DARKEST: "grayscale(20%) brightness(0.5);",
};
