"use es6";

import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const LoadingSection = styled.div`
  align-items: center;
  display: flex;
  font-family: "Roboto", sans-serif;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

const LoadingIndicator = () => {
  const [hasDelayEnded, setHasDelayEnded] = useState(false);

  const handleEndDelay = useCallback(() => {
    setHasDelayEnded(true);
  }, []);

  useEffect(() => {
    const delayTimer = setTimeout(handleEndDelay, 250);

    return function cleanup() {
      clearTimeout(delayTimer);
    };
  });

  if (!hasDelayEnded) {
    return null;
  }

  return <LoadingSection>Loading...</LoadingSection>;
};

export default LoadingIndicator;
