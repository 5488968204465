"use es6";

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS, SCREEN_SIZES } from "../style/constants";

const StyledLink = styled(
  ({
    color,
    fontSize,
    isCurrentRoute,
    isFooterMenu,
    mobileOnly,
    to,
    ...props
  }) => {
    return <Link to={to} {...props} />;
  }
)`
  color: ${({ color, isFooterMenu }) =>
    isFooterMenu ? color : COLORS.DARK_GRAY};
  font-size: ${({ fontSize, isFooterMenu }) =>
    isFooterMenu ? "18px" : fontSize || "24px"};
  cursor: pointer;
  font-family: "Playfair Display", serif;
  font-weight: 500;
  padding: ${({ isFooterMenu }) =>
    isFooterMenu ? "15px 15px 9px 15px" : "18px"};
  text-decoration: none;

  border-bottom: ${({ isCurrentRoute, isFooterMenu }) =>
    isCurrentRoute && !isFooterMenu && "2px solid"};
  border-bottom-color: ${COLORS.WHITE};

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    border-bottom-color: ${({ color }) => color || COLORS.WHITE};
    color: ${({ color }) => color || COLORS.WHITE};
    display: ${({ mobileOnly }) => (mobileOnly ? "none" : "block")};
    font-size: ${({ fontSize, isFooterMenu }) =>
      isFooterMenu ? "18px" : fontSize || "16px"};
    padding: 6px;
  }
`;

const propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
  isCurrentRoute: PropTypes.bool,
  isFooterMenu: PropTypes.bool,
  name: PropTypes.string.isRequired,
  mobileOnly: PropTypes.bool,
  route: PropTypes.string.isRequired,
  onMenuItemClick: PropTypes.func,
};

const defaultProps = {
  onMenuItemClick: () => {},
};

const MenuItem = ({
  color,
  fontSize,
  isCurrentRoute,
  isFooterMenu,
  name,
  mobileOnly,
  route,
  onMenuItemClick,
}) => {
  return (
    <>
      <StyledLink
        color={color}
        fontSize={fontSize}
        isCurrentRoute={isCurrentRoute}
        isFooterMenu={isFooterMenu}
        mobileOnly={mobileOnly}
        onClick={onMenuItemClick}
        to={`/${route}`}
      >
        {name}
      </StyledLink>
    </>
  );
};

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;

export default MenuItem;
