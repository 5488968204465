"use es6";

// moosilauke
import feature from "./feature.jpg";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpg";
import img5 from "./5.jpg";
import img6 from "./6.jpg";
import img7 from "./7.jpg";
import img8 from "./8.jpg";
import img9 from "./9.jpg";
import img10 from "./10.jpg";
import img11 from "./11.jpg";
import img12 from "./12.jpg";
import img13 from "./13.jpg";
import img14 from "./14.jpg";
import img15 from "./15.jpg";
import img16 from "./16.jpg";
import img17 from "./17.jpg";
import img18 from "./18.jpg";
import img19 from "./19.jpg";
import img20 from "./20.jpg";
import img21 from "./21.jpg";

export const post = {
  backgroundImageUrl: feature,
  backgroundPosition: "center 950px",
  body: `<p>Sixty days into staying at home due to COVID-19, it became clear we needed a break. We needed a break from our close living quarters – three people and a dog in a 900 square foot apartment – and we needed some fresh air. My brother Michael, the avid hiker he is, invited us to go hiking at one of the next mountains on his todo list, Mt. Moosilauke. It would be number 33 of 48 mountains in New Hampshire that he's hiked that's over 4,000 feet. Otherwise called the NH 48.</p>
  <p>It took a few weekends to get the weather right; leave it to New England to still be snowing in mid-May. But finally, the temperatures yielded and we drove up on a mild Sunday morning. In a few hours, we made it to the base, gathered our bags, and begun our ascent.</p>`,
  body2: `<p>It was everything we needed. The sound of the water streaming past us, the crunch of the snow under our feet, and seeing Raleigh effortlessly hop up the mountain, rock by rock. A couple hours later, we made it to (what we thought was) the summit and stopped for lunch. Despite not having coordinated our food, we were surprised to have packed the same lunch as my brother: PB&Js. Then again, we've all been revisiting this classic often in quarantine. </p>
  <p>A few steps into our return route down the other side, we passed a sign that read <i>Summit 0.2 mi</i> ... oops. Not long after, we made our way to the actual summit and were amazed by the uninterrupted, panoramic views. What a perfect escape. Spirits remained high as we made our way back down, and the ride home was a much-welcomed rest for our feet. We now know there's no better way to beat the Sunday scaries than with a 4,000 footer. One down, 47 left to go.</p>`,
  category: "Travel",
  date: "May 22, 2020",
  images: [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
  ],
  route: "moosilauke",
  subcategory: "Hiking",
  title: "Mt. Moosilauke",
};
