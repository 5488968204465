"use es6";

// bad good cards
import feature from "./feature.jpg";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpg";
import img5 from "./5.jpg";
import img6 from "./6.jpg";
import img7 from "./7.jpg";
import img8 from "./8.jpg";
import img9 from "./9.jpg";
import img10 from "./10.jpg";
import img11 from "./11.jpg";
import img12 from "./12.jpg";
import img13 from "./13.jpg";
import img14 from "./14.jpg";
import img15 from "./15.jpg";
import img16 from "./16.jpg";

export const post = {
  backgroundImageUrl: feature,
  backgroundPosition: "center -725px",
  body: `<p>In my life experience thus far, I've come across two types of people: the people who can draw, and the people who can't. I fall firmly into the latter category. There are so many things that seem small and so easy to draw. A milk carton? Nope. A dog? Forget about it. A person. Ha! So why did I go through a phase of spending an average of 3 hours on each handmade birthday card I made? I'm not quite sure. Most of me thinks I needed a vehicle for the tiny, puffy stickers I started collecting a year prior. </p>
  <p>It all started when I made the very first card to take an inside joke about Modelos one step further. To get the shape of the bottle right, I pulled up a picture on my computer and traced it. The final touches, of course, included a few tiny Llama stickers. The card was well received and I was hooked. Next up was a card for a friends' engagement, Dad's birthday, retirement, anniversay, and more. Each card was hyper-personalized and took an significant amount of time. I graduated from printer paper to cardstock, mismatch pens to a rainbow of 100 markers, and from scissors to an exacto knife.</p>
  `,
  body2: `<p>The plateau hit hard 6 months in. There was no more incentive. I brought up the idea of monetizing the cards – possibly advertising "Good bad cards" on Etsy – to my fiancé who asked me two crucial questions which gave me my answer. <i>How long does each card take? And how much did you want to sell them for?</i> Ok, ok, cleary cardmaking wasn't a hobby I could monetize. But did I have to monetize it at all? I thought about making a website to feature the cards and share with friends. A flawless business plan. I brought this new idea to my fiancé to which pitched me back a site for me to post <i>all</i> of my projects, some of my thoughts, and any of my ideas.</p>
  <p>Alas, the Wherevist was born.</p>
  `,
  category: "Whatever",
  date: "January 1, 2020",
  images: [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
  ],
  route: "bad-good-cards",
  subcategory: "Humor",
  title: "Bad Good Cards",
};
