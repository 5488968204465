"use es6";

// trudy snow
import feature from "./feature.jpg";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpg";
import img5 from "./5.jpg";

export const post = {
  backgroundImageUrl: feature,
  body: `<p>Growing up in New England prepared me for any and all weather. I spent many mornings before high school scraping snow off the family Suburban. And, on one occasion, shoveling snow out of the front two seats. The day prior was sunny and 60. You know, open sunroof weather. As someone who is perpetually cold, I developed a dress code for each temperature group. It's served me well – I am seldom cold or without backup layers. This past weekend, we were wearing short sleeves one day and snow boots the next. It was the first snow of the season. Raleigh loved every second. She hasn't been allowed out in the backyard since planted grass two months ago. For me, it was reminiscent of a Snow Day.
  </p>`,
  body2: `<p>It was a great sign when the phone would ring before we got up for school. Mom would then knock on each of our doors and confirm the news – school was cancelled. Once I tried, and failed, to fall back asleep, it was time for a snow test. Packing snow meant we would spend most of the afternoon building snowmen, forts, and snowballs. Once our gloves and socks became soaked through, we'd head inside for hot chocolate. Inevitably burning our tongues and warming our hands. Snow Days made the cold, sometimes unpleasant winter weather all worth it. All of these memories came rushing back to me as I experienced this first magic snowfall of prime packing snow. 
  </p>`,
  category: "Whatever",
  date: "October 30, 2020",
  images: [img1, img2, img3, img4, img5],
  route: "trudy-snow",
  subcategory: "Winter",
  title: "First Season Snow",
};
