"use es6";

import styled from "styled-components";
import { COLORS } from "../style/constants";

const Microcopy = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-family: "Roboto", sans-serif;
  font-size: 12px;
`;

export default Microcopy;
