"use es6";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  ANIMATION_DURATIONS,
  COLORS,
  CODE_COLORS,
  SCREEN_SIZES,
} from "../style/constants";
import autolinker from "../helpers/Autolinker";
import ViewGallery from "./ViewGallery";

const PostBodyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledPostBody = styled.div`
  animation: ${({ shouldAnimate }) =>
    shouldAnimate && `slideup ${ANIMATION_DURATIONS.SLOW}s ease`};
  font-family: "Libre Baskerville", serif;
  line-height: 1.7;
  max-width: calc(100vw - 60px);
  min-width: 50vw;
  padding-top: ${({ addMobilePadding }) => (addMobilePadding ? "45px" : "0")};

  :first-letter {
    float: left;
    font-size: 45px;
    line-height: 1;
    margin-top: 8px;
    padding-right: 15px;
  }

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    padding-top: 60px;

    :first-letter {
      font-size: 75px;
      margin-top: 3px;
    }
  }

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    max-width: ${({ maxWidth }) => maxWidth}px;
    padding-left: 60px;
  }

  a {
    color: ${COLORS.PINK};
    font-weight: 600;
    padding-left: 2px;
    text-decoration: none;

    &:hover {
      color: ${COLORS.DARK_PINK};
    }
  }

  code {
    font-family: Source Code Pro, monospace;
  }

  pre {
    background: ${CODE_COLORS.DARK_GRAY};
    font-family: Source Code Pro, monospace;
    color: ${COLORS.WHITE};
    overflow: scroll;
    padding: 30px 15px;

    .css {
      color: ${CODE_COLORS.LIGHT_YELLOW};
    }

    .const {
      color: ${CODE_COLORS.BLUE};
    }

    .ele {
      color: ${CODE_COLORS.TEAL};
    }

    .import {
      color: ${CODE_COLORS.PURPLE};
    }

    .num {
      color: ${CODE_COLORS.LIGHT_GREEN};
    }

    .sc {
      color: ${CODE_COLORS.ORANGE};
    }

    .paren {
      color: ${COLORS.WHITE};
    }

    .var {
      color: ${CODE_COLORS.LIGHT_BLUE};
    }
  }
`;

const ImageArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    align-items: flex-end;
  }
`;

const ImageBlock = styled.div`
  display: flex;
  height: 1400px;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    flex-direction: row;
    height: 420px;
  }
`;

const LargeImage = styled.div`
  background-color: gray;
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: cover;
  cursor: pointer;
  flex-basis: 33%;
  margin-right: 0;
  width 100%;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    flex-basis: 60%;
    margin-right: 9px;
  }
`;

const StackedImages = styled.div`
  display: flex;
  flex-basis: 67%;
  flex-direction: column;
  margin-left: 0px;
  margin-top: 18px;
  width: 100%;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    flex-basis: 40%;
    margin-left: 9px;
    margin-top: 0px;
  }
`;

const StackedImage = styled.div`
  background-color: gray;
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: cover;
  cursor: pointer;
  height: 100%;

  &:first-child {
    margin-bottom: 9px;
  }

  &:nth-child(2) {
    margin-top: 9px;
  }
`;

const PostBody = ({
  featImages,
  onPostImageClick,
  maxWidth,
  numberOfImages,
  post,
  shouldAnimate,
}) => {
  const renderImageBlock = useCallback(() => {
    return (
      <ImageArea>
        <ImageBlock>
          <LargeImage
            url={featImages[0]}
            onClick={onPostImageClick.bind(this, 0)}
          />
          <StackedImages>
            <StackedImage
              url={featImages[1]}
              onClick={onPostImageClick.bind(this, 1)}
            />
            <StackedImage
              url={featImages[2]}
              onClick={onPostImageClick.bind(this, 2)}
            />
          </StackedImages>
        </ImageBlock>
        <ViewGallery
          buttonText="View all images"
          numberOfImages={numberOfImages}
          onLinkClick={onPostImageClick}
          shouldReduceSpacing={true}
        />
      </ImageArea>
    );
  }, [featImages, numberOfImages, onPostImageClick]);

  const formattedBody = useMemo(() => {
    if (post.disableAutolinker) {
      return post.body;
    }
    return autolinker.link(post.body);
  }, [post]);

  const formattedBody2 = useMemo(() => {
    if (post.disableAutolinker) {
      return post.body2;
    }

    return autolinker.link(post.body2);
  }, [post]);

  return (
    <PostBodyContainer>
      <StyledPostBody
        data-measured-element="post-body"
        maxWidth={maxWidth}
        shouldAnimate={shouldAnimate}
        addMobilePadding={post.disableImages}
      >
        <div dangerouslySetInnerHTML={{ __html: formattedBody }} />
        {!post.disableImages && renderImageBlock()}
        <div dangerouslySetInnerHTML={{ __html: formattedBody2 }} />
      </StyledPostBody>
    </PostBodyContainer>
  );
};

export default PostBody;
