"use es6";

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { COLORS, ANIMATION_DURATIONS } from "../style/constants";

const propTypes = {
  disableOverflow: PropTypes.bool,
  disablePadding: PropTypes.bool,
  shouldAlignCenter: PropTypes.bool,
  shouldUseFullHeight: PropTypes.bool,
};

const StyledPage = styled.div`
  animation: fadein ${ANIMATION_DURATIONS.SLOW}s ease;
  color: ${COLORS.GREEN};
  display: flex;
  flex-direction: column;
  overflow: ${({ disableOverflow }) => (disableOverflow ? "hidden" : "auto")};
`;

const StyledContent = styled.div`
  height: ${({ shouldUseFullHeight }) =>
    shouldUseFullHeight ? "100%" : "90vh"};

  padding: ${({ disablePadding }) => !disablePadding && "60px"};

  ${({ shouldAlignCenter }) =>
    shouldAlignCenter &&
    "display: flex; justify-content: center; align-items: center;"};
`;

const Page = ({
  children,
  disableOverflow,
  disablePadding,
  shouldAlignCenter,
  shouldUseFullHeight,
}) => {
  return (
    <StyledPage disableOverflow={disableOverflow}>
      <StyledContent
        disablePadding={disablePadding}
        shouldAlignCenter={shouldAlignCenter}
        shouldUseFullHeight={shouldUseFullHeight}
      >
        {children}
      </StyledContent>
    </StyledPage>
  );
};

Page.propTypes = propTypes;
export default Page;
