"use es6";

// raleigh
import feature from "./feature.jpg";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpeg";
import img5 from "./5.jpg";
import img6 from "./6.jpg";
import img7 from "./7.jpg";
import img8 from "./8.jpg";
import img9 from "./9.jpg";

export const post = {
  backgroundImageUrl: feature,
  body: `<p>If you grew up like me, you grew up with three siblings and no pets. Mom wouldn't allow it. And for good reason – all four of us kids were born within five years. That puts me and my second oldest sister just fourteen months apart. Needless to say, we were a handfull and required around-the-clock maintenance. Between watching and cleaning up after us, there was no time left to watch and clean up after a pet. Clueless to this, us kids got more insistent as we got older. <i>Please Mom, pleeease. I promise I'll take care of it.</i> She held her position until we were in middle school and we were allowed two goldfish. They weren't all that fun nor long lasting. Sufice it to say, I didn't grow up around pets and had no understanding of what it meant to take care of one.</p>
  <p>Fast-forward ten years and my eldest sister gets a toy poodle. <i>He will not be allowed anywhere in the house</i>, Mom insisted, knowing full well accidents would happen. The moment my sister brought him through the door, that changed. We fell in love! He was the sweetest 3lb ball of fluff we'd ever seen. To this day, he's the most spoiled of us all, and our families' position on dogs was altered. I wanted to be around Ollie constantly. He brought me so much joy. So five years later when I really couldn't get enough of him, I started thinking about getting one of my own. I weighed the pros and cons – dogs are great companions, so expensive, cute to look at, time consuming, an excuse to get outside more, hard to travel with, and so on. The last straw in my fiancé and my decision was that fact that it would take a few months of hard work to train them. We didn't feel we had the time, so we settled on an answer: no dog.</p>`,
  body2: `<p>It may be a surprise to you and was undoubtedly a surprise to me to come home late from work in November and discover a dog in the apartment. As I walked in, I asked my fiancé <i>Whose dog is this?</i> The dog looked like a bigger Ollie. My fiancé, oddly filming me, said <i>She's yours.</i> A wave of emotions came over me. I sat down on the floor and had a good cry, hugging the dog tight. <i>Her name is Raleigh</i>, my fiancé told me.</p>
  <p>The long and short of it is that a colleague had a nine month old puppy he was trying to find a new home for. My fiancé just-so-happened to find that out and make the decision to keep her all in a span of 15 minutes. She was mostly-trained, our dream breed, and the happiest, best present I have ever received. Dog ownership is all I expected and more. The accdients are real, the effort is real, and bringing her outside in the dead of winter or the pouring rain isn't enjoyable. But coming home after being away for even 30 minutes and being greeted by her makes it all worth it. Raleigh is a good girl.</p>`,
  category: "Living",
  date: "January 4, 2020",
  images: [img1, img2, img3, img4, img5, img6, img7, img8, img9],
  route: "raleigh",
  subcategory: "Pets",
  title: "Raleigh",
};
