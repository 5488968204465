"use es6";

import React, { useMemo } from "react";
import NotFound from "./NotFound";
import Post from "./Post";
import { POSTS } from "../content/PostData";

const PostRouter = ({ match }) => {
  const postRouteName = match.params.postName;

  const post = useMemo(() => {
    return POSTS.find((post) => post.route === postRouteName);
  }, [postRouteName]);

  const currentPostIdx = useMemo(() => {
    if (!post) {
      return null;
    }
    return POSTS.indexOf(post);
  }, [post]);

  if (!post) {
    return <NotFound />;
  }

  return <Post currentPostIdx={currentPostIdx} post={post} />;
};

export default PostRouter;
