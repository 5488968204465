import React from "react";
import About from "./pages/About";
import Home from "./pages/Home";
import PostRouter from "./pages/PostRouter";
import Category from "./pages/Category";
import Finsta from "./pages/Finsta";
import SignupComplete from "./pages/SignupComplete";
import Signup from "./pages/Signup";
import NotFound from "./pages/NotFound";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import MenuContainer from "./components/MenuContainer";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <MenuContainer />
      <Switch>
        {/* REMEMBER TO UPDATE PAGE TITLE FOR EACH ROUTE */}
        <Route path="/" exact component={Home} />
        <Route path="/posts/:postName" exact component={PostRouter} />
        <Route path="/wherever/:categoryName" exact component={Category} />
        <Route path="/about" component={About} />
        <Route path="/kutchmeifyoucan" component={Finsta} />
        <Route path="/signup" component={Signup} />
        <Route path="/signup-complete" component={SignupComplete} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
