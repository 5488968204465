"use es6";

// nantucket
import pic1 from "./1.jpg";
import pic2 from "./2.jpg";
import pic3 from "./3.jpg";
import IMG_7242 from "./IMG_7242-min.jpg";
import IMG_7263 from "./IMG_7263-min.jpg";
import IMG_7280 from "./IMG_7280-min.jpg";
import IMG_7282 from "./IMG_7282-min.jpg";
import IMG_7284 from "./IMG_7284-min.jpg";
import IMG_7294 from "./IMG_7294-min.jpg";
import IMG_7295 from "./IMG_7295-min.jpg";
import IMG_7300 from "./IMG_7300-min.JPG";
import IMG_7355 from "./IMG_7355-min.jpg";
import IMG_7378 from "./IMG_7378-min.jpg";
import IMG_7411 from "./IMG_7411-min.jpeg";
import IMG_8374 from "./IMG_8374-min.jpg";
import p29150005 from "./29150005-min.jpg";
import p29150016 from "./29150016-min.jpg";
import p29150029 from "./29150029-min.jpg";
import p29150034 from "./29150034-min.jpg";
import p29170004 from "./29170004-min.jpg";
import p29170009 from "./29170009-min.jpg";
import p29170013 from "./29170013-min.jpg";
import p29170016 from "./29170016-min.jpg";
import p29180022 from "./29180022-min.jpg";
import p29180028 from "./29180028-min.jpg";
import p29180031 from "./29180031-min.jpg";
import p29180036 from "./29180036-min.jpg";
import feature from "./feature.jpg";
import feature3 from "./feature1.jpg";

export const post = {
  backgroundImageUrl: feature,
  body: `I started early, took my dog,<br/>
  And visited the sea;<br/>
  The mermaids in the basement<br/>
  Came out to look at me,<br/><br/>

  And frigates in the upper floor<br/>
  Extended hempen hands,<br/>
  Presuming me to be a mouse<br/>
  Aground, upon the sands.<br/><br/>
  
  But no man moved me till the tide<br/>
  Went past my simple shoe,<br/>
  And past my apron and my belt,<br/>
  And past my bodice too,<br/><br/>

  And made as he would eat me up<br/>
  As wholly as a dew<br/>
  Upon a dandelion's sleeve —<br/>
  And then I started too.<br/><br/>`,
  body2: `And he — he followed close behind;<br/>
  I felt his silver heel<br/>
  Upon my ankle, — then my shoes<br/>
  Would overflow with pearl.<br/><br/>

  Until we met the solid town,<br/>
  No man he seemed to know;<br/>
  And bowing with a mighty look<br/>
  At me, the sea withdrew.<br/><br/>
  - Emily Dickinson, <i>By the Sea</i>`,
  category: "Travel",
  date: "Summer 2021",
  images: [
    IMG_7280,
    IMG_7282,
    feature3,
    IMG_7263,
    IMG_7284,
    IMG_7294,
    IMG_7295,
    IMG_7242,
    IMG_7300,
    pic2,
    IMG_7355,
    pic3,
    IMG_7378,
    p29150005,
    p29150034,
    p29170004,
    p29170009,
    p29150016,
    p29170013,
    p29170016,
    p29180022,
    p29180028,
    p29180031,
    p29180036,
    pic1,
    IMG_7411,
    p29150029,
    IMG_8374,
  ],
  route: "ack",
  subcategory: "Mudslides",
  title: "Nantucket",
};
