"use es6";

// code - making the gallery
import feature from "./feature.png";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpeg";
import img4 from "./4.jpg";
import img5 from "./5.jpg";

export const post = {
  backgroundImageUrl: feature,
  body: `<p>I always thought it was hard to create an image gallery. I couldn't figure out the placement calculations. How much did the images need to shift? Do you need to set the position of each image? Many thanks to <a href="https://codepen.io/hexagoncircle/pen/jgGxKR" target="_blank" rel="noopener noreferrer">this Code Pen</a>, I learned it's easy to smoothly advance through images by adjusting the horizontal position of the component that wraps the images (i.e. the image wraper). You can do this by taking advantage of the CSS property: 
  <pre>
  <span class="var">transform</span>: <span class="css">translateX( -<span class="var">{{ amount }}</span>% )</span></pre>
  The translation amount is index of the current, centered image multipled by its relative size:
  <pre>
  <span class="var">amount</span> = <span class="var">{{ currentImageIndex }}</span> * (<span class="num">100</span> / <span class="var">{{ totalNumberOfImages }}</span> )</pre> 
  Another important thing to note is that there needs to be another wrapper around the image wrapper (i.e. a gallery wrapper) that is the same width as the underlying images. That way, we can make assumptions about the relative size of the image via the above  <code>amount</code> calculation.
  </p>`,
  body2: `<p>
  With React and styled components, the markup for the wrappers and images looks like so:
  <pre>
  <span class="import">import</span> <span class="var">styled</span> <span class="import">from</span> <span class="sc">"styled-components"</span>;
  
  ...
  
  <span class="const">const</span> <span class="var">width</span> = <span class="num">90vw</span>;
  
  <span class="const">const</span> <span class="var">GalleryWrapper</span> =  <span class="var">styled</span>.<span class="var">div</span><span class="sc">\`
    height: <span class="num">100vh</span>;
    width: <span class="const">$\{</span><span class="var">width</span><span class="const">}</span>;
  \`</span>;
  
  <span class="const">const</span> <span class="var">TranslateWrapper</span> = <span class="var">styled</span>.<span class="var">div</span><span class="sc">\`
    transform: translateX(-<span class="const">$\{</span><span class="paren">({</span> <span class="var">amount</span> <span class="paren">})</span> <span class="const">=&gt;</span> <span class="var">amount</span}%)<span class="const">}</span>;
    transition: transform 1s ease-in-out;
  \`</span>;
  
  <span class="const">const</span> <span class="var">GalleryImage</span> = <span class="var">styled</span>.<span class="var">img</span><span class="sc">\`
    height: <span class="num">75vh</span>;
    width: <span class="const">$\{</span><span class="var">width</span><span class="const">}</span>;
  \`</span>;
  
  ...
  
  &lt;<span class="ele">GalleryWrapper</span>&gt;
    &lt;<span class="ele">TranslateWrapper</span> <span class="var">amount</span>={<span class="var">currentIdx</span> * (<span class="num">100</span> / <span class="var">images.length</span>)} &gt;
      <span class="const">{</span><span class="var">images</span>.<span class="css">map</span>((<span class="var">src</span>, <span class="var">idx</span>) <span class="const">=&gt;</span> &lt;<span class="ele">GalleryImage</span> <span class="var">data-idx</span>=<span class="const">{</span><span class="var">idx</span><span class="const">}</span> <span class="var">src</span>=<span class="const">{</span><span class="var">src</span><span class="const">}</span> /&gt; )<span class="const">}</span>
    &lt;/<span class="ele">TranslateWrapper</span>&gt;
  &lt;/<span class="ele">GalleryWrapper</span>&gt;</pre>
  With that, you'll also need to manage the <code>currentIdx</code> state to make it possible to navigate to new images on click. Initializing and managing the state is pretty trivial with React's <code>useState</code> hook. In order to navigate to a given image on click, each image requires an <code>onClick</code> handler that will update the <code>currentIdx</code>:
  <pre>
  <span class="import">import</span> { <span class="var">useState</span> } <span class="import">from</span> <span class="sc">"react"</span>;
  
  ...
  
  <span class="const">const</span> [<span class="var">currentIdx</span>, <span class="var">setCurrentIdx</span>] = <span class="css">useState</span>(<span class="num">0</span>);
  <span class="const">const</span> <span class="css">handleImageClick</span> = <span class="css">useCallback</span>(
    (<span class="var">evt</span>) <span class="const">=></span> {
      <span class="const">const</span> <span class="var">newSelectedItemIdx</span> = <span class="var">evt</span>.<span class="var">currentTarget</span>.<span class="css">getAttribute</span>(<span class="sc">"data-idx"</span>);
      <span class="import">if</span> (<span class="var">newSelectedItemIdx</span> !== <span class="var">currentIdx</span>) {
        <span class="css">setCurrentIdx</span>(<span class="css">parseInt</span>(<span class="var">newSelectedItemIdx</span>, <span class="num">10</span>));
      }
    },
    [<span class="var">currentIdx</span>]
    );</pre>
    These are the bones of the gallery. From here, you can add previous/next buttons with similar callbacks. Lastly, style the heck out of it – give the gallery a background, change the opacity of the images on hover, and add in counts. Here's my example that I use throughout this site:
    </p>`,
  category: "Code",
  date: "March 7, 2020",
  disableAutolinker: true,
  disableImages: true,
  forceViewGalleryFooter: true,
  images: [img1, img2, img3, img4, img5],
  route: "code",
  subcategory: "CSS Transitions",
  title: "Building a gallery",
};
