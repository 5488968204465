"use es6";
import React, { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { POSTS } from "../content/PostData";
import { Link } from "react-router-dom";
import { ANIMATION_DURATIONS, COLORS, SCREEN_SIZES } from "../style/constants";
import arrow from "../assets/arrow.svg";

const propTypes = {
  currentPostIdx: PropTypes.number,
  onNavigate: PropTypes.func.isRequired,
};

const StyledPostFooter = styled.section`
  align-items: center;
  animation: slideup ${ANIMATION_DURATIONS.SLOW}s ease;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    flex-direction: row;
    padding-top: 60px;
  }
`;

const PostFooterButton = styled(({ to, isLeft, ...props }) => {
  return <Link to={to} {...props} />;
})`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  margin-top: 24px;
  padding: 0 45px 0 45px;
  position: relative;
  text-decoration: none;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    margin: 0;
  }

  &:hover {
    img {
      left: ${({ isLeft }) => isLeft && "-75px"};
      right: ${({ isLeft }) => !isLeft && "-75px"};
    }
  }
`;

const ArrowAndTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${({ shouldReverseFlexDirection }) =>
    shouldReverseFlexDirection ? "column-reverse" : "column"};
  flex-grow: 1;
  flex-shrink: 0;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    flex-direction: row;
  }
`;

const PostTitle = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-family: "Playfair Display", serif;
  font-size: 15px;
  z-index: 1;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    font-size: 21px;
  }
`;

const Arrow = styled.img`
  border-radius: 50%;
  cursor: pointer;
  padding: 9px;
  width: 60px;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    position: absolute;
    right: 0;
    top: -30px;
    transition: right ${ANIMATION_DURATIONS.FAST}s ease,
      left ${ANIMATION_DURATIONS.FAST}s ease;
  }
`;

const ArrowLeft = styled(Arrow)`
  left: 0;
  right: auto;
  transform: rotate(180deg);
`;

const PostThumbnail = styled.div`
  display: none;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    background-image: url(${({ url }) => url});
    background-position: center;
    background-size: cover;
    display: block;
    height: 100px;
    left: ${({ isLeft }) => isLeft && "0"};
    right: ${({ isLeft }) => !isLeft && "0"};
    opacity: ${({ isHovering }) => (isHovering ? "0.25" : "0")};
    position: absolute;
    transition: opacity ${ANIMATION_DURATIONS.FAST}s ease;
    width: 100px;
  }
`;

const PostFooter = ({ currentPostIdx, onNavigate }) => {
  const [isHoveringOnNext, setIsHoveringOnNext] = useState(false);
  const [isHoveringOnPrevious, setIsHoveringOnPrevious] = useState(false);

  const nextPost = useMemo(() => {
    const isLastPost = currentPostIdx === POSTS.length - 1;
    const nextPostIdx = isLastPost ? 0 : currentPostIdx + 1;
    return POSTS[nextPostIdx];
  }, [currentPostIdx]);

  const previousPost = useMemo(() => {
    const isFirstPost = currentPostIdx === 0;
    const previousPostIdx = isFirstPost ? POSTS.length - 1 : currentPostIdx - 1;
    return POSTS[previousPostIdx];
  }, [currentPostIdx]);

  const handleMouseLeave = useCallback((type) => {
    if (window.innerWidth < SCREEN_SIZES.SMALLISH) {
      return;
    }

    if (type === "next") {
      return setIsHoveringOnNext(false);
    }
    return setIsHoveringOnPrevious(false);
  }, []);

  const handleMouseEnter = useCallback((type) => {
    if (window.innerWidth < SCREEN_SIZES.SMALLISH) {
      return;
    }

    if (type === "next") {
      return setIsHoveringOnNext(true);
    }
    return setIsHoveringOnPrevious(true);
  }, []);

  // TODO LATER ANNA: once we hit 4+ posts in each category, only go to next in category
  return (
    <StyledPostFooter>
      <PostFooterButton
        isLeft={true}
        onClick={onNavigate}
        onMouseEnter={handleMouseEnter.bind(this, "previous")}
        onMouseLeave={handleMouseLeave.bind(this, "previous")}
        to={`/posts/${previousPost.route}`}
      >
        <PostThumbnail
          backgroundPosition={previousPost.backgroundPosition}
          isHovering={isHoveringOnPrevious}
          isLeft={true}
          url={previousPost.backgroundImageUrl}
        />
        <ArrowAndTitle>
          <ArrowLeft src={arrow} alt="Previous" />
          <PostTitle>{previousPost.title}</PostTitle>
        </ArrowAndTitle>
      </PostFooterButton>
      <PostFooterButton
        onClick={onNavigate}
        onMouseEnter={handleMouseEnter.bind(this, "next")}
        onMouseLeave={handleMouseLeave.bind(this, "next")}
        to={`/posts/${nextPost.route}`}
      >
        <ArrowAndTitle shouldReverseFlexDirection={true}>
          <PostTitle>{nextPost.title}</PostTitle>
          <Arrow src={arrow} alt="Next" />
        </ArrowAndTitle>
        <PostThumbnail
          backgroundPosition={nextPost.backgroundPosition}
          isHovering={isHoveringOnNext}
          url={nextPost.backgroundImageUrl}
        />
      </PostFooterButton>
    </StyledPostFooter>
  );
};

PostFooter.propTypes = propTypes;
export default PostFooter;
