"use es6";

import React from "react";
import logo from "../assets/logo.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ANIMATION_DURATIONS, SCREEN_SIZES } from "../style/constants";

const StyledLogo = styled.img`
  animation: slideright ${ANIMATION_DURATIONS.SLOW}s ease;
  cursor: pointer;
  margin-left: -3px;
  margin-top: -10px;
  max-width: ${({ shouldEnforceMaxWidth }) =>
    shouldEnforceMaxWidth ? "400px" : "1000px"};
  width: 75vw;

  @media screen and (min-width: ${SCREEN_SIZES.SMALL}) {
    width: 50vw;
  }

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    margin-left: -7px;
    margin-top: -17px;
  }
`;

const Logo = ({ shouldEnforceMaxWidth }) => {
  return (
    <Link to="">
      <StyledLogo
        alt="Whereverist Logo"
        src={logo}
        shouldEnforceMaxWidth={shouldEnforceMaxWidth}
      />
    </Link>
  );
};

export default Logo;
