"use es6";

// chasing snow
import feature from "./feature.jpg";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpg";
import img5 from "./5.jpg";
import img6 from "./6.jpg";
import img7 from "./7.jpg";
import img8 from "./8.jpg";
import img9 from "./9.jpg";
import img10 from "./10.jpg";
import img11 from "./11.jpg";
import img12 from "./12.jpg";
import img13 from "./13.jpg";
import img14 from "./14.jpg";
import img15 from "./15.jpg";
import img16 from "./16.jpg";
import img17 from "./17.jpg";
import img18 from "./18.jpg";
import img19 from "./19.jpg";
import img20 from "./20.jpg";
import img21 from "./21.jpg";
import img22 from "./22.jpg";
import img23 from "./23.jpg";
import img24 from "./24.jpg";
import img25 from "./25.jpg";
import img26 from "./26.jpg";
import img27 from "./27.jpg";
import img28 from "./28.jpg";
import img29 from "./29.jpg";
import img30 from "./30.jpg";
import img31 from "./31.jpg";
import img32 from "./32.jpg";
import img33 from "./33.jpg";
import img34 from "./34.jpg";
import img35 from "./35.jpg";
import img36 from "./36.jpg";
import img37 from "./37.jpg";
import img38 from "./38.jpg";
import img39 from "./39.jpg";

export const post = {
  backgroundImageUrl: feature,
  backgroundPosition: "bottom",
  body: `<p >Whose woods these are I think I know.<br></p><p >
  His house is in the village though;<br></p><p >
  He will not see me stopping here<br></p><p >
  To watch his woods fill up with snow.<br></p><p ><br></p><p >
  My little horse must think it queer<br></p><p >
  To stop without a farmhouse near<br></p><p >
  Between the woods and frozen lake<br></p><p >
  The darkest evening of the year.<br>`,
  body2: `<p >
  He gives his harness bells a shake<br></p><p >
  To ask if there is some mistake.<br></p><p >
  The only other sound’s the sweep<br></p><p >
  Of easy wind and downy flake.<br></p><p ><br></p><p >
  The woods are lovely, dark and deep, <br></p><p >
  But I have promises to keep,<br></p><p >
  And miles to go before I sleep,<br></p><p >
  And miles to go before I sleep.<br></p><br><p>- Robert Frost, <i>Stopping by Woods on a Snowy Evening</i></p>
  <br/><br/><br/>
  <p>Photos from Sugarbush, Park City, and Sunday River`,
  category: "Travel",
  date: "February 18, 2021",
  images: [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
  ],
  route: "chasing-snow",
  subcategory: "Dropping In",
  title: "Chasing Snow",
};
