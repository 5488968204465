"use es6";

import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { SCREEN_SIZES } from "../style/constants";
import { FINSTA_PICS } from "../content/FinstaData";
import Footer from "../components/Footer";
import Page from "../components/Page";
import FinstaGrid from "../components/FinstaGrid";
import Gallery from "../components/Gallery";
import usePageTitle from "../components/usePageTitle";

const StyledFinsta = styled.div`
  padding-left: 0;
  padding-right: 0;
  padding-top: 100px;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 120px;
  }

  @media screen and (min-width: ${SCREEN_SIZES.LARGE}) {
    padding-left: 120px;
    padding-right: 120px;
  }
`;

const Finsta = () => {
  usePageTitle("kutchmeifyoucan");
  const [isShowingGallery, setIsShowingGallery] = useState(false);
  const [defaultPostIdx, setDefaultPostIdx] = useState(0);

  const handleToggleGallery = useCallback(
    (postIdx) => {
      setIsShowingGallery(!isShowingGallery);

      // ensure it's a number... otherwise it's the event
      if (!isNaN(postIdx)) {
        setDefaultPostIdx(postIdx);
      }

      if (!isShowingGallery) {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = "fixed";
      } else {
        setDefaultPostIdx(0);
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }
    },
    [isShowingGallery]
  );

  return (
    <Page disablePadding={true} shouldUseFullHeight={true}>
      {isShowingGallery && (
        // TODO ANNA: image captions
        <Gallery
          defaultPostIdx={defaultPostIdx}
          images={FINSTA_PICS}
          onToggleGallery={handleToggleGallery}
        />
      )}
      <StyledFinsta>
        <FinstaGrid
          onClickFinstaPhoto={handleToggleGallery}
          photos={FINSTA_PICS}
        />
      </StyledFinsta>
      <Footer />
    </Page>
  );
};

export default Finsta;
