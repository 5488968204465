"use es6";

import React from "react";
import styled from "styled-components";
import CategorySubheading from "./CategorySubheading";
import { SCREEN_SIZES, ANIMATION_DURATIONS } from "../style/constants";
import FilteredBackgroundImage from "./FilteredBackgroundImage";
import PostLink from "./PostLink";
import PostPreviewTitle from "./PostPreviewTitle";

const SecondaryPhoto = styled(FilteredBackgroundImage)`
  animation: fadein ${ANIMATION_DURATIONS.SLOW}s ease;
  justify-content: center;
  height: 45vh;
  width: 100vw;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    flex-direction: row;
    height: 60vh;
    width: 50vw;
  }
`;

const FlexContents = styled.div`
  display: flex;
  flex-direction: column;
`;

const SecondaryPostPreview = ({ post }) => {
  return (
    <PostLink to={`/posts/${post.route}`}>
      <SecondaryPhoto url={post.backgroundImageUrl} key={post.route}>
        <FlexContents>
          <PostPreviewTitle>{post.title}</PostPreviewTitle>
          <CategorySubheading
            category={post.category}
            subcategory={post.subcategory}
          />
        </FlexContents>
      </SecondaryPhoto>
    </PostLink>
  );
};

export default SecondaryPostPreview;
