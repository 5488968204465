"use es6";

import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import Menu from "./Menu";
import { SCREEN_SIZES } from "../style/constants";
import { useLocation } from "react-router-dom";
import StickyMenu from "./StickyMenu";

const StyledMenu = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  width: 100vw;
  z-index: 3;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    flex-wrap: nowrap;
  }
`;

// TODO LATER ANNA: add search
const MenuContainer = ({ location }) => {
  const { pathname } = useLocation();
  // home page and category pages
  const useMenuWithBigLogo =
    pathname === "/" ||
    pathname.includes("wherever") ||
    pathname.includes("about");

  return (
    <>
      {!useMenuWithBigLogo && <StickyMenu />}
      <StyledMenu>
        <Logo shouldEnforceMaxWidth={!useMenuWithBigLogo} />
        <Menu />
      </StyledMenu>
    </>
  );
};

export default MenuContainer;
