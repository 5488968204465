"use es6";

import React from "react";
import styled from "styled-components";
import { COLORS, SCREEN_SIZES } from "../style/constants";
import PropTypes from "prop-types";

const propTypes = {
  category: PropTypes.string,
  color: PropTypes.string,
  subcategory: PropTypes.string,
};

const Subheading = styled.div`
  color: ${({ color }) => color || COLORS.PINK};
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  padding-top: ${({ useTopPadding }) => useTopPadding && "12px"};
  z-index: 1;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    padding-top: 0;
  }
`;

const CategorySubheading = ({
  category,
  color,
  subcategory,
  useTopPadding,
}) => {
  return (
    <Subheading color={color} useTopPadding={useTopPadding}>
      {category} / {subcategory}
    </Subheading>
  );
};

CategorySubheading.propTypes = propTypes;
export default CategorySubheading;
