"use es6";

import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import Menu from "./Menu";
import { COLORS, SCREEN_SIZES } from "../style/constants";

const MIN_DIST_FROM_TOP = 200;

const FixedMenu = styled.div`
  align-items: center;
  background-color: ${COLORS.WHITE};
  box-shadow: ${({ shouldUseFullHeight }) =>
    shouldUseFullHeight ? "none" : `0px 10px 30px ${COLORS.OPAQUE_BLACK}`};
  display: flex;
  overflow: hidden;
  position: fixed;
  height: ${({ shouldUseFullHeight }) =>
    shouldUseFullHeight ? "100vh" : "50px"};
  top: ${({ top }) => top}px;
  transition: top 0.5s ease;
  width: 100vw;
  z-index: 2;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    flex-wrap: nowrap;
    height: 75px;
  }
`;

const StickyMenu = () => {
  const [shouldShowStickyMenu, setShouldShowStickyMenu] = useState(false);
  const [shouldUseFullHeight, setShouldUseFullHeight] = useState(false);
  const [scrollPos, setScrollPos] = useState(window.scrollY);

  // when the mobile menu is showing, we need the sticky menu to be the height of
  // the full page, since we want the mobile menu to be the height of the full page
  const handleMobileMenuOpen = useCallback(() => {
    setShouldUseFullHeight(true);
  }, []);

  const handleMobileMenuClose = useCallback(() => {
    setShouldUseFullHeight(false);
  }, []);

  const handleCheckPlacement = useCallback(() => {
    const newScrollPos = window.scrollY;
    const isScrollingUp = scrollPos > newScrollPos;

    const hasEnoughRoomToShowMenu = newScrollPos > MIN_DIST_FROM_TOP;

    if (isScrollingUp && hasEnoughRoomToShowMenu) {
      if (!shouldShowStickyMenu) {
        setShouldShowStickyMenu(true);
      }
      return setScrollPos(newScrollPos);
    }

    setScrollPos(newScrollPos);
    return setShouldShowStickyMenu(false);
  }, [shouldShowStickyMenu, scrollPos]);

  useEffect(() => {
    window.addEventListener("scroll", handleCheckPlacement);

    return function cleanup() {
      window.removeEventListener("scroll", handleCheckPlacement);
    };
  }, [handleCheckPlacement]);

  return (
    <FixedMenu
      top={shouldShowStickyMenu || shouldUseFullHeight ? 0 : -120}
      shouldUseFullHeight={shouldUseFullHeight}
    >
      <Logo shouldEnforceMaxWidth={true} />
      <Menu
        color={COLORS.DARK_GRAY}
        onMobileMenuOpen={handleMobileMenuOpen}
        onMobileMenuClose={handleMobileMenuClose}
      />
    </FixedMenu>
  );
};

export default StickyMenu;
