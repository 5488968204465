"use es6";

// kitchen
import feature from "./full.jpg";
import dining from "./dining.jpg";
import shelf from "./shelf.jpg";
import alternate from "./alternate.jpg";
import img2 from "./2.jpeg";
import img3 from "./3.jpeg";
import img4 from "./4.jpeg";
import img5 from "./5.jpeg";
import img6 from "./6.jpeg";
import img7 from "./7.jpeg";
import img8 from "./8.jpeg";
import img8a from "./8a.jpeg";
import img9 from "./9.jpeg";
import img10a from "./10a.jpeg";
import img10b from "./10b.jpeg";
import img12 from "./12.jpeg";
import img12a from "./12a.jpeg";
import img12aa from "./12aa.jpeg";
import img13 from "./13.jpeg";
import img14 from "./14.jpeg";
import img15 from "./15.jpeg";
import img15a from "./15a.jpeg";
import img16 from "./16.jpeg";
import img16a from "./16a.jpeg";
import img16b from "./16b.jpeg";
import img16c from "./16c.jpeg";
import img17 from "./17.jpeg";
import img18 from "./18.jpeg";
import img19 from "./19.jpeg";
import img20 from "./20.jpeg";
import img21 from "./21.jpeg";
import img22 from "./22.jpeg";
import img23 from "./23.jpeg";

export const post = {
  backgroundImageUrl: feature,
  backgroundPosition: "center bottom",
  body: `
  <p>"You missed a spot" has never rung more true than the night that we came home after some major kitchen demo to find that a couple feet of the wall we had planned to take down was... still there. If I wasn't already going into labor, this was enough to send me the rest of the way.</p>
  <p>Seven months earlier, when I was just two months pregnant, we decided to take the plunge. We decided to redo the kitchen because "when else would be a good time after the baby arrives?". Hindsight has informed me that there <i>probably</i> was a time or two better. It seemed like a good plan at the time, though. The supply chain was catching up, the estimate for when our cabinets would be ready was safely a couple weeks before my due date, and the HubSpot stock was on a downturn, so clearly this was a good time to do it.</p>
  
  <p>We signed the contract with a kitchen cabinet company and found a local GC to do the rest of the work – taking out a wall, removing a radiator, replacing a door, repairing the floor, installing new windows, painting, trim ... etc. We moved forward with the first major step of taking out the wall between the kitchen and dining room the week before my due date. Though things were delayed a few weeks at this point, we still had time before I was due. This brings us to the part about the night we arrived home to part of that wall still being there. This was another reminder that the project wouldn't be as straightforward as we originally thought.</p>
  `,
  body2: `
  <p>Fast forward 8 more weeks, our baby is 8 weeks old at this point, and we still don't have a home base. This is our doing and yes, of course, this is a lucky problem to have. Nonetheless it's hard to cope with when newly postpartum. Has anyone ever told you that postpartum recovery can really suck? Tell a friend. I digress. My wonderful parents hosted us on and off over this time, and we incrementally returned home to some progress over those 8 weeks. The windows were installed. The crumbling basement door was replaced. The ceiling was smoothed over and painted. The electrical was roughed out. If only we could get an estimate on those cabinets...</p>
  <p>Another 4 weeks later, on Halloween, the cabinets were delivered. A glorious day. That night, we dressed up as the "Flyntstones" and trick-or-treated with the kids in my sisters neighborhood. Another 4 weeks after that, our countertops were installed. OMG. I ordered a clippy counter seat on Amazon, dashed home to blissfully organize the cabinets, and asked my Dad when he could come over to hook up our sink. He said he could be over <i>that night</i>, even though this meant his "roommates" of the past 3 months were  moving out.</p>
  <p>We held our breath as we turned on the water and tapped our <a href="https://www.amazon.com/dp/B0064OW4WS" target="blank" ref="noreferrer" rel="noopener">faucet</a> for the first time. Running water! The project was coming to a close and we could now move home to a place where we could wash baby bottles and breast pump parts outside of our bathroom. It felt luxurious on that day, and it still does today. The clippy counter seat is still the main place our baby - who is approaching one year of life! - eats. There is a special joy to sharing this space with someone new.</p>
  <br />
  <br />
  <br />
  <p><i>Our picks:</i></p>
  <p>• <a href="https://www.amazon.com/dp/B0064OW4WS" target="blank" ref="noreferrer" rel="noopener">Delta Faucet</a></p>
  <p>• <a href="https://www.bestbuy.com/site/samsung-17-5-cu-ft-3-door-french-door-counter-depth-refrigerator-with-wifi-and-twin-cooling-plus-stainless-steel/6443500.p?skuId=6443500" target="blank" ref="noreferrer" rel="noopener">Samsung Fridge</a></p>
  <p>• <a href="https://www.bestbuy.com/site/samsung-stormwash-24-top-control-built-in-dishwasher-with-autorelease-dry-3rd-rack-48-dba-stainless-steel/6361073.p?skuId=6361073" target="blank" ref="noreferrer" rel="noopener">Samsung Dishwasher</a></p>
  <p>• <a href="https://www.google.com/url?q=https://www.bestbuy.com/site/bosch-500-series-1-6-cu-ft-built-in-microwave-stainless-steel/5699000.p?skuId%3D5699000%26ref%3DNS%26loc%3D101" target="blank" ref="noreferrer" rel="noopener">Bosch Microwave</a></p>
  <p>• <a href="https://www.bestbuy.com/site/ge-30-convertible-range-hood-stainless-steel/8153219.p?skuId=8153219" target="blank" ref="noreferrer" rel="noopener">GE Hood</a></p>
  <p>• <a href="https://www.amazon.com/gp/product/B08GG5RPYN" target="blank" ref="noreferrer" rel="noopener">Kraus Sink</a></p>
  <p>• <a href="https://www.amazon.com/gp/product/B00004U9JO" target="blank" ref="noreferrer" rel="noopener">Insinkerator Garbage Disposal</a>, <a href="https://www.amazon.com/gp/product/B078H38Q1M" target="blank" ref="noreferrer" rel="noopener">Installation Kit</a></p>
  <p>• <a href="https://www.amazon.com/gp/product/B002I631GY" target="blank" ref="noreferrer" rel="noopener">Matching Insinkerator Sink Flange</a></p>
  <p>• <a href="https://www.pellabranch.com/" target="blank" ref="noreferrer" rel="noopener">Pella Windows</a> (Custom)</p>
  <p>• <a href="https://www.benjaminmoore.com/en-us/paint-colors/color/oc-27/balboa-mist" target="blank" ref="noreferrer" rel="noopener">Benjamin Moore Balboa Mist</a> (Wall Color)</p>
  <p>• <a href="https://www.benjaminmoore.com/en-us/paint-colors/color/oc-130/cloud-white" target="blank" ref="noreferrer" rel="noopener">Benjamin Moore Cloud White</a> (Cabinet Color)</p>
  <p>• <a href="https://www.amerock.com/Products/Detail/pid/4236/s" target="blank" ref="noreferrer" rel="noopener">Amerock Drawer Pulls</a></p>
  <p>• <a href="https://www.amerock.com/Products/Detail/pid/4252/s" target="blank" ref="noreferrer" rel="noopener">Amerock Cabinet Knobs</a></p>
  <p>• <a href="https://spectrumquartz.com/product/vitality-sample/" target="blank" ref="noreferrer" rel="noopener">Quartz Countertops</a></p>
  <p>• <a href="https://www.amazon.com/Inglesina-Fast-Table-Chair-Cream/dp/B00IOGIN60"  target="blank" ref="noreferrer" rel="noopener">Clippy Seat</a>, <a href="https://www.potterybarnkids.com/products/fast-table-dining-tray/?catalogId=10&sku=766217&cm_ven=PLA&cm_cat=Google&cm_pla=Baby%20Gear%20%3E%20High%20Chairs%20%26%20Booster%20Seats&region_id=658780&cm_ite=766217_14726664444"  target="blank" ref="noreferrer" rel="noopener">Tray</a></p>
  <p>• <a href="https://www.homedepot.com/p/eightdoors-30-in-x-80-in-x-1-3-8-in-Shaker-White-Primed-1-Panel-Solid-Core-Wood-Interior-Slab-Door-70188014803035SH/310969642" target="blank" ref="noreferrer" rel="noopener">Shaker Door</a></p>
  <p>• <a href="https://superiorkitchens.net/" target="blank" ref="noreferrer" rel="noopener">Superior Kitchen</a></p>
  <p>• <a href="https://www.hkfconstructionmedford.com/" target="blank" ref="noreferrer" rel="noopener">HKF Construction</a></p>
  `,
  category: "Living",
  date: "July 8, 2023",
  images: [
    alternate,
    shelf,
    dining,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img8a,
    img9,
    img10a,
    img10b,
    img12,
    img12a,
    img12aa,
    img13,
    img14,
    img15,
    img15a,
    img16,
    img16a,
    img16b,
    img16c,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
  ],
  route: "kitchen",
  subcategory: "Going Into Labor",
  title: '"Let\'s Redo The Kitchen"',
};
