"use es6";

// miami
import feature from "./feature.jpg";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpg";
import img5 from "./5.jpg";
import img6 from "./6.jpg";
import img7 from "./7.jpg";
import img8 from "./8.jpg";
import img9 from "./9.jpg";
import img10 from "./10.jpg";
import img11 from "./11.jpg";
import img12 from "./12.jpg";
import img13 from "./13.jpg";
import img14 from "./14.jpg";
import img15 from "./15.jpg";
import img16 from "./16.jpg";
import img17 from "./17.jpg";
import img18 from "./18.jpg";
import img19 from "./19.jpg";
import img20 from "./20.jpg";
import img21 from "./21.jpg";
import img22 from "./22.jpg";
import img23 from "./23.jpg";
import img24 from "./24.jpg";
import img25 from "./25.jpg";
import img26 from "./26.jpg";
import img27 from "./27.jpg";
import img28 from "./28.jpg";
import img29 from "./29.jpg";
import img30 from "./30.jpg";
import img31 from "./31.jpeg";
import img32 from "./32.jpg";

export const post = {
  backgroundImageUrl: feature,
  body: `<p>Sand on skin,</p>
  <p>the smell of sunscreen,</p>
  <p>bare feet running</p>
  <p>down wooden planks to the sea,</p>
  <p>laughter echoing off white waves as the sun</p>
  <p>rises over a blue horizon.</p>
  <p>You remind me of summer mornings,</p>
  <p>bigger than a single moment,</p>
  <p>you are an entire season of joy</p>
  <p>- Alix Klingenberg, <i>Summer Mornings</i></p>`,
  body2: `<p>While the spring showers fade</p>
  <p>And the summer heat begins</p>
  <p>We get a chance at everything</p>
  <p>All over again</p>
  <p>- Edmund Day, <i>Summer Chance</i></p>`,
  category: "Travel",
  date: "March 18, 2021",
  images: [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
  ],
  route: "miami",
  subcategory: "Banana Daiquiris",
  title: "Miami",
};
