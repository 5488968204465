"use es6";

import React from "react";
import pin from "../assets/pin.png";

const SHARE_ICONS = [{ src: pin, type: "pinterest" }];

/**
 * TODO LATER ANNA: get icons (pinterest + facebook)
 * for share links then add them to the posts like so:
 *
 * @example links object for post
 *
 * {
 *    facebook: 'http://facebook.com..',
 *    pinterest: 'http://pinterest.com..',
 *    twitter: 'http://twitter.com..',
 *
 * }
 *
 * create share links via http://www.sharelinkgenerator.com/
 */
const ShareLinks = ({ links }) => {
  if (!links) {
    return null;
  }

  const renderShareLink = (icon) => {
    return (
      <a
        href={links[icon.type]}
        target="_blank"
        rel="noopener noreferrer"
        key={icon.type}
      >
        <img src={icon.src} alt="pinterest" />
      </a>
    );
  };

  return SHARE_ICONS.map(renderShareLink);
};

export default ShareLinks;
