"use es6";

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const PostLink = styled(({ to, ...props }) => {
  return <Link to={to} {...props} />;
})`
  cursor: pointer;
  position: relative;
  text-decoration: none;
`;

export default PostLink;
