"use es6";
import React, { useCallback } from "react";
import Footer from "../components/Footer";
import PostPreviewGrid from "../components/PostPreviewGrid";
import { POSTS } from "../content/PostData";
import NotFound from "./NotFound";
import usePageTitle from "../components/usePageTitle";

const Category = ({ match }) => {
  const categoryRouteName = match.params.categoryName;
  const categoryPosts = POSTS.filter(
    (post) => post.category.toLowerCase() === categoryRouteName
  );

  usePageTitle(
    categoryRouteName.charAt(0).toUpperCase() +
      categoryRouteName.slice(1, categoryRouteName.length)
  );

  const renderGrid = useCallback(() => {
    if (!categoryPosts) {
      return null;
    }
    return (
      <PostPreviewGrid
        maxPosts={4}
        posts={categoryPosts}
        useTopPadding={true}
      />
    );
  }, [categoryPosts]);

  if (!categoryPosts.length) {
    return <NotFound />;
  }

  return (
    <>
      {renderGrid()}
      <Footer disableMarginTop={true} />
    </>
  );
};

export default Category;
