"use es6";

import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { COLORS, ANIMATION_DURATIONS, SCREEN_SIZES } from "../style/constants";
import useLazyImage from "../components/useLazyImage";

const GalleryImageWrapper = styled.li`
  background-color: ${COLORS.WHITE};
  cursor: pointer;
  display: flex;
  height: 100%;
  margin-right: 12px;
  text-align: center;
`;

const StyledImage = styled.div`
  background-color: ${COLORS.GRAY};
  background-image: url(${({ url }) => url});
  background-position: ${({ position }) => position || "center"};
  background-size: cover;
  display: block;
  opacity: ${({ isCurrentImage }) => (isCurrentImage ? "1" : "0.25")};
  transition: opacity ${ANIMATION_DURATIONS.MEDIUM}s
      cubic-bezier(0.25, 0.46, 0.45, 0.84),
    transform ${ANIMATION_DURATIONS.MEDIUM}s
      cubic-bezier(0.25, 0.46, 0.45, 0.84);
  width: 90vw;
  user-select: none;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    min-width: 420px;
    width: 30vw;
  }

  &:hover {
    opacity: ${({ isCurrentImage }) => (isCurrentImage ? "1" : "0.55")};
    transform: ${({ isPreviousImage, isNextImage }) => {
      if (isPreviousImage) {
        return "translateX(2%)";
      }
      if (isNextImage) {
        return "translateX(-2%)";
      }
      return "none";
    }};
  }
`;

const Caption = styled.div`
  font-family: "Playwright", serif;
  font-size: 15px;
  padding-top: 6px;
`;

const GalleryImage = ({
  caption,
  currentIdx,
  idx,
  onImageClick,
  onSetNextImage,
  onSetPreviousImage,
  position,
  src,
}) => {
  const imgSrc = useLazyImage(src);

  // TODO LATER ANNA: lazy load images
  const [horizontalTouchPoint, setHorizontalTouchPoint] = useState(null);
  const handleSetHorizontalTouchPoint = useCallback((evt) => {
    const touchStartX = evt.touches[0].clientX;
    setHorizontalTouchPoint(touchStartX);
  }, []);

  const handleImageSwipe = useCallback(
    (evt) => {
      const touchEndX = evt.changedTouches[0].clientX;
      const swipeDistance = touchEndX - horizontalTouchPoint;

      if (Math.abs(swipeDistance) < 10) {
        return null;
      }

      if (Math.sign(swipeDistance) === -1) {
        return onSetNextImage();
      }

      return onSetPreviousImage();
    },
    [onSetPreviousImage, onSetNextImage, horizontalTouchPoint]
  );

  return (
    <GalleryImageWrapper
      data-idx={idx}
      onClick={onImageClick}
      onTouchStart={handleSetHorizontalTouchPoint}
      onTouchEnd={handleImageSwipe}
    >
      <StyledImage
        alt="image"
        isCurrentImage={currentIdx === idx}
        isNextImage={currentIdx + 1 === idx}
        isPreviousImage={currentIdx - 1 === idx}
        position={position}
        url={imgSrc}
      />
      {caption && <Caption>{caption}</Caption>}
    </GalleryImageWrapper>
  );
};

export default GalleryImage;
