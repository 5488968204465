"use es6";

import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { COLORS, ANIMATION_DURATIONS, SCREEN_SIZES } from "../style/constants";
import lilarrow from "../assets/lilarrow.svg";
import GalleryImage from "./GalleryImage";
import Microcopy from "./Microcopy";
import grayCloseX from "../assets/grayCloseX.svg";

const GalleryContainer = styled.div`
  animation: fadein ${ANIMATION_DURATIONS.FAST}s ease;
  background-color: ${COLORS.OPAQUE_WHITE};
  height: 100%;
  position: fixed;
  top: -${window.scrollY}px;
  width: 100vw;
  z-index: 3;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CloseButtonContainer = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  width: 100vw;
`;

const CloseButton = styled.img`
  cursor: pointer;
  margin: 24px;
  width: 24px;
`;

const GalleryWrapper = styled.div`
  align-items: center;
  display: flex;
  height: calc(100% - 160px);
  flex-grow: 1;
  width: 90vw;
  position: absolute;
  top: 72px;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    min-width: 420px;
    width: 30vw;
  }
`;

// thanks to https://codepen.io/hexagoncircle/pen/jgGxKR
const TranslateWrapper = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  transform: translateX(-${({ translateAmount }) => translateAmount}%);
  transition: transform ${ANIMATION_DURATIONS.MEDIUM}s
    cubic-bezier(0.25, 1, 0.35, 1);

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    padding-inline-start: unset;
  }
`;

const ArrowContainer = styled.div`
  align-items: center;
  display: flex;
  letter-spacing: 1px;
  padding-bottom: 12px;
  position: absolute;
  bottom: 0;
`;

const Arrow = styled.img`
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  margin-left: 18px;
  margin-right: 18px;
  padding: 9px;
  transition: background 0.3s ease;
  user-select: none;
  width: 30px;

  &:hover {
    background-color: rgba(225, 149, 130, 0.5);
  }
`;

const ArrowRight = styled(Arrow)`
  transform: rotate(180deg);
`;

const ESCAPE_KEY_CODE = 27;
const LEFT_ARROW_KEY_CODE = 37;
const RIGHT_ARROW_KEY_CODE = 39;

const Gallery = ({
  defaultPostIdx,
  featuredImageBackgroundPosition,
  images,
  onToggleGallery,
}) => {
  const [currentIdx, setCurrentIdx] = useState(defaultPostIdx);

  const handleSetPreviousImage = useCallback(() => {
    const previousItemIdx = currentIdx - 1;
    if (previousItemIdx < 0) {
      // is first item, set to last
      return setCurrentIdx(images.length - 1);
    }
    return setCurrentIdx(previousItemIdx);
  }, [currentIdx, images.length]);

  const handleSetNextImage = useCallback(() => {
    const nextItemIdx = currentIdx + 1;
    if (nextItemIdx === images.length) {
      // is last item, set to first
      return setCurrentIdx(0);
    }
    return setCurrentIdx(nextItemIdx);
  }, [currentIdx, images.length]);

  const handleKeyDown = useCallback(
    (evt) => {
      switch (evt.keyCode) {
        case LEFT_ARROW_KEY_CODE:
          handleSetPreviousImage();
          break;
        case RIGHT_ARROW_KEY_CODE:
          handleSetNextImage();
          break;
        case ESCAPE_KEY_CODE:
          onToggleGallery();
          break;
        default:
          break;
      }
    },
    [handleSetNextImage, handleSetPreviousImage, onToggleGallery]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleImageClick = useCallback(
    (evt) => {
      const newSelectedItemIdx = evt.currentTarget.getAttribute("data-idx");
      if (newSelectedItemIdx !== currentIdx) {
        setCurrentIdx(parseInt(newSelectedItemIdx, 10));
      }
    },
    [currentIdx]
  );

  const renderImage = useCallback(
    (src, idx) => {
      // the last image is the featured image
      const isFeaturedImage = idx === images.length - 1;
      return (
        <GalleryImage
          currentIdx={currentIdx}
          idx={idx}
          key={`gallery-image-${idx}`}
          onImageClick={handleImageClick}
          onSetNextImage={handleSetNextImage}
          onSetPreviousImage={handleSetPreviousImage}
          position={
            isFeaturedImage ? featuredImageBackgroundPosition : undefined
          }
          src={src}
        />
      );
    },
    [
      currentIdx,
      featuredImageBackgroundPosition,
      handleImageClick,
      handleSetNextImage,
      handleSetPreviousImage,
      images.length,
    ]
  );

  return (
    <GalleryContainer>
      <CloseButtonContainer>
        <CloseButton alt="Close" onClick={onToggleGallery} src={grayCloseX} />
      </CloseButtonContainer>
      <GalleryWrapper>
        <TranslateWrapper translateAmount={currentIdx * (100 / images.length)}>
          {images.map(renderImage)}
        </TranslateWrapper>
      </GalleryWrapper>
      <ArrowContainer>
        <Arrow onClick={handleSetPreviousImage} src={lilarrow} alt="Previous" />
        <Microcopy>{`${currentIdx + 1}/${images.length}`}</Microcopy>
        <ArrowRight onClick={handleSetNextImage} src={lilarrow} alt="Next" />
      </ArrowContainer>
    </GalleryContainer>
  );
};

export default Gallery;
