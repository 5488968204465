"use es6";
import React from "react";
import styled from "styled-components";
import { ANIMATION_DURATIONS, COLORS } from "../style/constants";
import { Link } from "react-router-dom";

const StyledButton = styled.button`
  color: ${COLORS.WHITE};
  cursor: pointer;
  background: ${COLORS.LIGHT_BLUE_SUMMER};
  border: none;
  padding: 12px 30px;
`;

const Description = styled.div`
  color: ${({ color }) => color || COLORS.DARK_GRAY};
  font-family: "Playfair Display";
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 18px;
`;

const SignupButtonContainer = styled.div`
  animation: slideup ${ANIMATION_DURATIONS.SLOW}s ease;
  margin-top: 50px;
  text-align: center;
`;

const SignupButton = ({ color, disableAnimation }) => {
  return (
    <SignupButtonContainer>
      <Description color={color} disableAnimation={disableAnimation}>
        Sign up for updates about new posts:
      </Description>
      <Link to="/signup">
        <StyledButton>Subscribe</StyledButton>
      </Link>
    </SignupButtonContainer>
  );
};

export default SignupButton;
