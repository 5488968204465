"use es6";

// recap 2022
import p450A86DD from "./450A86DD-1557-4DF6-96B3-99FEF24CAE74-min.jpg";
import p529FB1FC from "./529FB1FC-02CF-4EB3-B95C-43BCD6DD8AB9-min.jpg";
import p6D7DAFC8 from "./6D7DAFC8-042C-41A9-8AB6-28295DA2A614-min.jpg";
import p70B5B92D from "./70B5B92D-90A4-4D70-9B63-9235BFF78F45-min.jpg";
import p7711115A from "./7711115A-CCCF-47A7-9BE5-287BCC290B9A-min.jpg";
import p77570534 from "./77570534-AD24-43B2-8623-ECB8849DF4F0-min.jpg";
import p95FE8323 from "./95FE8323-610C-43E5-8E77-4A9F9753551F-min.jpg";
import A194822C from "./A194822C-6BDF-4828-A7D6-C9AAAECF3E41-min.jpg";
import C5833D98 from "./C5833D98-BD33-4D8D-99B7-B1810D9076E8-min.jpg";
import CC5B4C44 from "./CC5B4C44-92D5-41A1-80BA-4EBD55622AB7-min.jpg";
import E1757039 from "./E1757039-A9A2-4E96-9021-F07B07D8F708-min.jpg";
import F06A080D from "./F06A080D-CA18-4956-9705-4B9306DC062A-min.jpg";
import FullSizeRender from "./FullSizeRender-min.jpg";
import IMG_1651 from "./IMG_1651-min.jpg";
import feature from "./IMG_1770-min.jpg";
import IMG_1821 from "./IMG_1821-min.jpg";
import IMG_1901 from "./IMG_1901-min.jpg";
import IMG_2107 from "./IMG_2107-min.jpg";
import IMG_2239 from "./IMG_2239-min.jpg";
import IMG_3045 from "./IMG_3045-min.jpg";
import IMG_3083 from "./IMG_3083-min.jpg";
import IMG_3218 from "./IMG_3218-min.jpg";

export const post = {
  backgroundImageUrl: feature,
  body: `Becoming a mother</br>
  Skiing in the frigid Northeast<br/>
  Nausea, heartburn<br/>
  Countless feedings<br/>
  Endless diapers<br/>
  Crying<br/>
  Loving<br/>
  Admiring California<br/>
  `,
  body2: `Organizing, preparing, cleaning<br/>
  Painting<br/>
  Going to Target<br/>
  Resting my feet<br/>
  Being parched<br/>
  A full heart<br/>
  Being there for each other<br/>
  Staring at you<br/>
  So much beauty<br/>`,
  category: "Living",
  date: "December 31, 2022",
  images: [
    p6D7DAFC8,
    IMG_3045,
    C5833D98,
    CC5B4C44,
    IMG_1901,
    IMG_3218,
    A194822C,
    IMG_1821,
    IMG_3083,
    p7711115A,
    IMG_2239,
    p70B5B92D,
    IMG_2107,
    IMG_1651,
    p77570534,
    FullSizeRender,
    p95FE8323,
    p529FB1FC,
    E1757039,
    p450A86DD,
    F06A080D,
  ],
  route: "2022",
  subcategory: "Motherhood",
  title: "2022: In Review",
};
