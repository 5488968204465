"use es6";

// DON'T FORGET TO COMPRESS

// July 2023
import img78 from "../content/finsta/78-car.jpg";
import img77 from "../content/finsta/77-hair.jpg";
import img76 from "../content/finsta/76-roz-backyard.jpg";
import img75 from "../content/finsta/75-beach.jpg";

// June 2023
import img74 from "../content/finsta/74-roz-wayland.jpg";

// May 2023
import img73 from "../content/finsta/73-nc.jpg";

// April 2023
import img72 from "../content/finsta/72-luke-art.jpg";

// February 2023
import img71 from "../content/finsta/71-attic.jpg";

// January 2023
import img70 from "../content/finsta/70-warren.JPG";
import img69 from "../content/finsta/69-sunset.JPG";
import img68 from "../content/finsta/68-cayman.JPG";
import img67 from "../content/finsta/67-cayman.JPG";

// September 2022
import img66 from "../content/finsta/66-september.jpg";

// August 2022
import img65 from "../content/finsta/65-fam.jpg";
import img64b from "../content/finsta/64b-nursery.jpg";
import img64a from "../content/finsta/64a-preggo.jpg";

// July 2022
import img64 from "../content/finsta/64-ptown.jpg";

// May 2022
import img63 from "../content/finsta/63-palm-springs.jpg";
import img62 from "../content/finsta/62-bbed-bach.jpg";
import img61 from "../content/finsta/61-mill-valley.jpg";

// March 2022
import img61_az from "../content/finsta/60---az.jpeg";

// February 2022
import img60_backyard from "../content/finsta/60--backyard.jpeg";

// January 2022
import img60 from "../content/finsta/60-sb.jpeg";
import img59 from "../content/finsta/59-park.jpeg";

// December 2021
import img58 from "../content/finsta/58-gloucester.jpeg";
import img57 from "../content/finsta/57-harkers.jpeg";
import img56 from "../content/finsta/56-christmas.jpeg";

// November 2021
import img55 from "../content/finsta/55-wine.jpg";

// October 2021
import img54 from "../content/finsta/54-poipu.jpeg";
import img53 from "../content/finsta/53-heli.jpg";
import img52 from "../content/finsta/52-golf.jpeg";
import img51 from "../content/finsta/51-kauai.jpeg";
import maui from "../content/finsta/51--maui-drink.jpeg";
import img50 from "../content/finsta/50-honeymoon.jpeg";
import img50_wedding from "../content/finsta/50--wedding.jpg";
import img49 from "../content/finsta/49-portland.jpg";

// September 2021
import img48 from "../content/finsta/48-kitchen.jpg";
import img47 from "../content/finsta/47-portland.jpeg";
import img46 from "../content/finsta/46-ack-bach.jpeg";

// July 2021
import img45 from "../content/finsta/45-philly.jpeg";

// June 2021
import img44 from "../content/finsta/44-clarks-cove.jpg";
import img43 from "../content/finsta/43-fence-beach.jpg";
import img42 from "../content/finsta/42-beach-luke-anna.jpg";
import img41 from "../content/finsta/41-ack-house.jpg";
import img40 from "../content/finsta/40-beach-group.jpg";
import img39 from "../content/finsta/39-ack.jpg";

// May 2021
import img38 from "../content/finsta/38-marco.jpg";
import img37 from "../content/finsta/37-marco.jpg";
import img36 from "../content/finsta/36-marco.jpg";
import img35 from "../content/finsta/35-hill.jpg";
import img34 from "../content/finsta/34-south-end.jpeg";

// March 2021
import img33 from "../content/finsta/33-miami.jpg";
import img32 from "../content/finsta/32-sunday-river.jpg";
import img31 from "../content/finsta/31-park-city.jpg";

// January 2021
import img30 from "../content/finsta/30-sugarbush.jpg";
import img29 from "../content/finsta/29-hill.jpg";

// December 2020
import img28 from "../content/finsta/28-roz-office-12_04_2020.jpeg";

// October 2020
import img25 from "../content/finsta/25-fells-becca_10_18_2020.jpg";
import img23 from "../content/finsta/23-beals-brush_10_10_2020.jpg";
import img22 from "../content/finsta/22-beals-lobster-10_10_2020.JPG";
import img21 from "../content/finsta/21-beals-bonfire-10_10_2020.JPG";
import img20 from "../content/finsta/20-beal-wave_10_10_2020.jpeg";

// September 2020
import img19 from "../content/finsta/19-doggos-09_27_2020.jpg";
import img18 from "../content/finsta/18-roz-closet-09_28_2020.jpg";
import img17 from "../content/finsta/17-bday-hike_09_26_2020.jpg";
import img16 from "../content/finsta/16-luke-bday_09_26_2020.jpg";
import img15 from "../content/finsta/15-fetch-09_16_2020.jpg";
import img14 from "../content/finsta/14-peloton-09_11_2020.jpg";
import img13 from "../content/finsta/13-steph-engagement-09_06_2020.jpg";

// August 2020
import img12 from "../content/finsta/12-hm-chairs-08_31_2020.jpg";
import img11 from "../content/finsta/11-camping_08_21_2020.jpg";
import img10 from "../content/finsta/10-dad-60th-08_15_2020.jpg";
import img9 from "../content/finsta/9-closet-08_14_2020.jpg";
import img8 from "../content/finsta/8-lincoln-nh-08_04_2020.jpg";
import img7 from "../content/finsta/7-river-rocks-08_04_2020.jpg";
import img6 from "../content/finsta/6-emily-bday-08_03_2020.jpg";

// July 2020
import img5 from "../content/finsta/5-pool-07_26_2020.jpg";
import img4 from "../content/finsta/4-wedding-postponement_07_20-2020.jpg";
import img3 from "../content/finsta/3-fells-07_15_2020.jpg";
import img2 from "../content/finsta/2-ily-luke-07_14_2020.jpg";
import img1 from "../content/finsta/1-river-07_04_2020.jpg";

// NOTE: legacy photos are prefixed with '0' and increment in the opposite direction
// NOTE: legacy photos are prefixed with '0' and increment in the opposite direction
// NOTE: legacy photos are prefixed with '0' and increment in the opposite direction

// June 2020
import img0_1 from "../content/finsta/0_2020_06_15.jpg";
import img0_2 from "../content/finsta/0_2020_06_11.jpg";
import img0_3 from "../content/finsta/0_2020_06_03.jpg";
import img0_4 from "../content/finsta/0_2020_06_01.jpg";
import img0_5 from "../content/finsta/0_2020_05_29.jpg";

// March 2020
import img0_6 from "../content/finsta/0_2020_03_30.jpg";
import img0_7 from "../content/finsta/0_2020_03_28.jpg";
import img0_8 from "../content/finsta/0_2020_03_26.jpg";
import img0_9 from "../content/finsta/0_2020_03_25.jpg";

// Februrary 2020
import img0_10 from "../content/finsta/0_2020_02_21.jpg";
import img0_11 from "../content/finsta/0_2020_02_20.jpg";
import img0_12 from "../content/finsta/0_2020_02_19.jpg";
import img0_13 from "../content/finsta/0_2020_02_18.jpg";
import img0_14 from "../content/finsta/0_2020_02_17.jpg";
import img0_15 from "../content/finsta/0_2020_02_15.jpg";
import img0_16 from "../content/finsta/0_2020_02_14.jpg";
import img0_17 from "../content/finsta/0_2020_02_13.jpg";

// January 2020
import img0_18 from "../content/finsta/0_2020_01_25.jpg";
import img0_19 from "../content/finsta/0_2020_01_22.jpg";

// December 2019
import img0_20 from "../content/finsta/0_2019_12_28.jpg";
import img0_21 from "../content/finsta/0_2019_12_26.jpg";
import img0_22 from "../content/finsta/0_2019_12_03.jpg";

// November 2019
import img0_23 from "../content/finsta/0_2019_11_23.jpg";
import img0_24 from "../content/finsta/0_2019_11_01.jpg";
import img0_25 from "../content/finsta/0_2019_11_09.jpg";
import img0_26 from "../content/finsta/0_2019_11_02.jpg";

// October 2019
import img0_27 from "../content/finsta/0_2019_10_30.jpg";
import img0_28 from "../content/finsta/0_2019_10_27.jpg";
import img0_29 from "../content/finsta/0_2019_10_26.jpg";
import img0_30 from "../content/finsta/0_2019_10_18.jpg";
import img0_31 from "../content/finsta/0_2019_10_19.jpg";

// September 2019
import img0_32 from "../content/finsta/0_2019_09_29.jpg";
import img0_33 from "../content/finsta/0_2019_09_26.jpg";
import img0_34 from "../content/finsta/0_2019_09_11.jpg";

// August 2019
import img0_35 from "../content/finsta/0_2019_08_30.jpg";
import img0_36 from "../content/finsta/0_2019_08_17.jpg";
import img0_37 from "../content/finsta/0_2019_08_10.jpg";

// July 2019
import img0_38 from "../content/finsta/0_2019_07_20.jpg";
import img0_39 from "../content/finsta/0_2019_07_06.jpg";
import img0_40 from "../content/finsta/0_2019_07_05.jpg";
import img0_41 from "../content/finsta/0_2019_07_04.jpg";
import img0_42 from "../content/finsta/0_2019_07_03.jpg";
import img0_43 from "../content/finsta/0_2019_07_02.jpg";
import img0_44 from "../content/finsta/0_2019_07_01.jpg";
import img0_45 from "../content/finsta/0_2019_07_01-2.jpg";
import img0_46 from "../content/finsta/0_2019_07_01-3.jpg";
import img0_47 from "../content/finsta/0_2019_07_01-4.jpg";
import img0_48 from "../content/finsta/0_2019_07_01-5.jpg";

// June 2019
import img0_49 from "../content/finsta/0_2019_06_29-1.jpg";
import img0_50 from "../content/finsta/0_2019_06_29.jpg";
import img0_51 from "../content/finsta/0_2019_06_27.jpg";

// May 2019
import img0_52 from "../content/finsta/0_2019_05_26.jpg";
import img0_53 from "../content/finsta/0_2019_05_26-1.jpg";
import img0_54 from "../content/finsta/0_2019_05_26-2.jpg";
import img0_55 from "../content/finsta/0_2019_05_18.jpg";
import img0_56 from "../content/finsta/0_2019_05_11.jpg";
import img0_57 from "../content/finsta/0_2019_05_05.jpg";
import img0_58 from "../content/finsta/0_2019_05_03.jpg";
import img0_59 from "../content/finsta/0_2019_05_03-2.jpg";

// April 2019
import img0_60 from "../content/finsta/0_2019_04_28.jpg";
import img0_61 from "../content/finsta/0_2019_04_27.jpg";
import img0_62 from "../content/finsta/0_2019_04_27-2.jpg";
import img0_63 from "../content/finsta/0_2019_04_25.jpg";
import img0_64 from "../content/finsta/0_2019_04_15.jpg";

// March 2019
import img0_65 from "../content/finsta/0_2019_03_13.jpg";
import img0_66 from "../content/finsta/0_2019_03_08.jpg";
import img0_67 from "../content/finsta/0_2019_03_04.jpg";
import img0_68 from "../content/finsta/0_2019_03_02.jpg";

// February 2019
import img0_69 from "../content/finsta/0_2019_02_19.jpg";
import img0_70 from "../content/finsta/0_2019_02_02.jpg";

// January 2019
import img0_71 from "../content/finsta/0_2019_01_21.jpg";
import img0_72 from "../content/finsta/0_2019_01_20.jpg";

// December 2019
import img0_73 from "../content/finsta/0_2018_12_31.jpg";
import img0_74 from "../content/finsta/0_2018_12_25.jpg";
import img0_75 from "../content/finsta/0_2018_12_30.jpg";
import img0_76 from "../content/finsta/0_2018_12_26.jpg";
import img0_77 from "../content/finsta/0_2018_12_10.jpg";
import img0_78 from "../content/finsta/0_2018_09_10.jpg";
import img0_79 from "../content/finsta/0_2018_12_04.jpg";

// November 2018
import img0_80 from "../content/finsta/0_2018_11_28.jpg";
import img0_81 from "../content/finsta/0_2018_09_30.jpg";
import img0_82 from "../content/finsta/0_2018_11_04.jpg";
import img0_83 from "../content/finsta/0_2018_11_01.jpg";

// October 2018
import img0_84 from "../content/finsta/0_2018_10_13.jpg";
import img0_85 from "../content/finsta/0_2018_10_05.jpeg";

// September 2018
import img0_86 from "../content/finsta/0_2018_09_24.jpg";
import img0_87 from "../content/finsta/0_2018_07_05.jpg";
import img0_88 from "../content/finsta/0_2018_09_23.jpg";
import img0_89 from "../content/finsta/0_2018_09_24-2.jpg";
import img0_90 from "../content/finsta/0_2018_09_23-2.jpg";

export const FINSTA_PICS = [
  img78,
  img77,
  img76,
  img75,
  img74,
  img73,
  img72,
  img71,
  img70,
  img69,
  img68,
  img67,
  img66,
  img65,
  img64a,
  img64,
  img64b,
  img63,
  img62,
  img61,
  img61_az,
  img60_backyard,
  img60,
  img59,
  img58,
  img57,
  img56,
  img55,
  img54,
  img53,
  img52,
  img51,
  maui,
  img50,
  img50_wedding,
  img49,
  img48,
  img47,
  img46,
  img45,
  img44,
  img43,
  img42,
  img41,
  img40,
  img39,
  img38,
  img37,
  img36,
  img35,
  img34,
  img33,
  img32,
  img31,
  img30,
  img29,
  img28,
  img25,
  img23,
  img22,
  img21,
  img20,
  img19,
  img18,
  img17,
  img16,
  img15,
  img14,
  img13,
  img12,
  img11,
  img10,
  img9,
  img8,
  img7,
  img6,
  img5,
  img4,
  img3,
  img2,
  img1,

  // NOTE: legacy photos inrecement in the opposite direction
  img0_1,
  img0_2,
  img0_3,
  img0_4,
  img0_5,
  img0_6,
  img0_7,
  img0_8,
  img0_9,
  img0_10,
  img0_11,
  img0_12,
  img0_13,
  img0_14,
  img0_15,
  img0_16,
  img0_17,
  img0_18,
  img0_19,
  img0_20,
  img0_21,
  img0_22,
  img0_23,
  img0_24,
  img0_25,
  img0_26,
  img0_27,
  img0_28,
  img0_29,
  img0_30,
  img0_31,
  img0_32,
  img0_33,
  img0_34,
  img0_35,
  img0_36,
  img0_37,
  img0_38,
  img0_39,
  img0_40,
  img0_41,
  img0_42,
  img0_43,
  img0_44,
  img0_45,
  img0_46,
  img0_47,
  img0_48,
  img0_49,
  img0_50,
  img0_51,
  img0_52,
  img0_53,
  img0_54,
  img0_55,
  img0_56,
  img0_57,
  img0_58,
  img0_59,
  img0_60,
  img0_61,
  img0_62,
  img0_63,
  img0_64,
  img0_65,
  img0_66,
  img0_67,
  img0_68,
  img0_69,
  img0_70,
  img0_71,
  img0_72,
  img0_73,
  img0_74,
  img0_75,
  img0_76,
  img0_77,
  img0_78,
  img0_79,
  img0_80,
  img0_81,
  img0_82,
  img0_83,
  img0_84,
  img0_85,
  img0_86,
  img0_87,
  img0_88,
  img0_89,
  img0_90,
];
