"use es6";

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CategorySubheading from "./CategorySubheading";
import { ANIMATION_DURATIONS, COLORS, SCREEN_SIZES } from "../style/constants";
import ShareLinks from "./ShareLinks";

const propTypes = {
  onAnimationEnd: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  shouldAnimate: PropTypes.bool,
};

const TitleBox = styled.div`
  animation: ${({ shouldAnimate }) =>
    shouldAnimate && `slideright ${ANIMATION_DURATIONS.SLOW}s ease`};
  background-color: ${COLORS.LIGHT_PINK};
  margin-top: -90px;
  min-width: 222px;
  padding: 30px 36px 36px 36px;
  position: relative;
  text-align: center;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    padding: 36px 45px 45px 45px;
    text-align: auto;
  }
`;

const Title = styled.h1`
  color: ${COLORS.DARK_GRAY};
  font-size: 36px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
  line-height: 1;
  padding-bottom: 12px;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    font-size: 60px;
    padding-bottom: 15px;
  }
`;

const CategoryAndDate = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    flex-direction: row;
  }
`;

const Microcopy = styled.span`
  color: ${COLORS.DARK_GRAY};
  font-family: "Roboto", sans-serif;
  font-size: 12px;
`;

const SeparatorDot = styled(Microcopy)`
  display: none;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    display: block;
    font-size: 12px;
    padding-left: 9px;
    padding-right: 9px;
  }
`;

const PaddedMicrocopy = styled(Microcopy)`
  padding-top: 12px;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    padding: 0;
  }
`;

const PostTitle = ({ onAnimationEnd, post, shouldAnimate }) => {
  return (
    <TitleBox onAnimationEnd={onAnimationEnd} shouldAnimate={shouldAnimate}>
      <Title>{post.title}</Title>
      <CategoryAndDate>
        <CategorySubheading
          color={COLORS.DARK_GRAY}
          category={post.category}
          subcategory={post.subcategory}
          useTopPadding={true}
        />
        <SeparatorDot>•</SeparatorDot>
        <PaddedMicrocopy>{post.date}</PaddedMicrocopy>
      </CategoryAndDate>
      <ShareLinks links={post.shareLinks} />
    </TitleBox>
  );
};

PostTitle.propTypes = propTypes;
export default PostTitle;
