"use es6";

import React from "react";
import styled from "styled-components";
import { COLORS } from "../style/constants";

const GalleryBlocks = styled.div`
  display: flex;
`;

const BlockRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const GalleryBlock = styled.div`
  background-color: ${COLORS.DARK_GRAY};
  height: 7px;
  margin: 1px;
  width: 7px;
`;

const GalleryIcon = () => {
  const renderBlockRow = (key) => {
    return (
      <BlockRow key={key}>
        <GalleryBlock />
        <GalleryBlock />
      </BlockRow>
    );
  };

  return (
    <GalleryBlocks>
      {["blocks-1", "blocks-2"].map(renderBlockRow)}
    </GalleryBlocks>
  );
};

export default GalleryIcon;
