"use es6";

// wedding planning
import feature from "./feature.jpg";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpg";
import img5 from "./5.jpg";
import img6 from "./6.jpg";
import img7 from "./7.jpg";
import img8 from "./8.jpg";
import img9 from "./9.jpg";
import img10 from "./10.jpg";

export const post = {
  backgroundImageUrl: feature,
  backgroundPosition: "center -180px",
  backgroundPositionMobile: "center right",
  body: `<p>The thought of getting married and being able to plan a gigantic celebration around it has always been an exciting thought to me. When my boyfriend of four years asked me to marry him in May 2019, I was ecstatic and so ready to plan. We opted to plan every inch of our wedding ourselves. We locked down the essentials fairly quick – the date, the venue, and the caterer and let the other details fall into place over time. My biggest worry was about the weather. <i>I hope it doesn't rain</i>, I'd think. And looking back, rain should have been the least of my worries.</p>
  <p><i>"Are you going to cancel your wedding?"</i></p>
  <p>Since the COVID-19 pandemic hit the U.S. in March, I've been asked this question countless times. <i>"Hopefully not! We're waiting to make the call"</i>" has been my default response. It's a tremendous privilage to have planned a wedding to the extent we did, so I've been taking my dissappointment in stride. While I'm still hopeful for the Fall, it's easy to fill uncertainty with worry. This whole experience has taught me a lot about managing expectations and having patience. I've taken a lot of inspiration from my fiancé on those fronts.</p>`,
  body2: `<p>Planning this wedding during a pandemic has given me a healthy dose of perspective. As we make our way towards October, I'm less and less focused on the single day celebration and more focused on having Luke in my life and being a good partner. I'm focusing less on the future and more on each day as it's happening. It's so easy to get caught up in life's <i>when</i>s – <i>"I will be happy when... I can't wait until when..."</i> But the truth is, <i>when</i>s are uncertain. Situations change, goals change, pandemics happen, and <i>when</i> turns to <i>if</i>. The only thing we have full control over is our attitude towards what's happening <i>now</i>.</p>
  <p>Will we still get married this October? Maybe. Will it be a big celebration like we planned? I'm not sure. What I am sure of is that I picked the right person. There's no one else I'd rather live through a pandemic with.</p>
  <br />  
  <p><i>Photos by the talented <a href="https://www.kellygiarrocco.com/" target="_blank" rel="noopener noreferrer">Kelly Giarrocco</a></i></p>
  `,
  images: [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10],
  category: "Whatever",
  date: "May 9, 2020",
  route: "pandemic-planning",
  subcategory: "Wedding",
  title: "Engaged in a Pandemic",
};
