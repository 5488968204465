"use es6";

// wedding website
import feature from "./feature.png";

export const post = {
  backgroundImageUrl: feature,
  body: `<p>When you're getting married to an artisist and you yourself are a programmer, you really have no excuse but to build your own wedding website. Or so we thought. After a couple months of hard work and negotiating specs, Luke and I published theflynts2020.com. Argualy the hardest part was choosing the domain name. The easiest part, however, was deploying the site thanks to <a href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer">Netlify</a>.</p>`,
  body2: `<p>Netlify is an all-in-one platform for automating modern web projects. And it's <i>so easy</i> to use. When your codebase is on GitHub, all there is to do is link your repo to Netlify and input and your production build command. From there, Netlifty automatically builds and deploys your website on commit. If you want a more complex setup (e.g. branch deploys), you can manage that directly from the Netlify UI. If ever you get stuck, their documentation will give you any and all answers you seek.</p>
  <p>I know what you're thinking... <i>How much does it cost?</i> Nothing. It's free-free free. Unless you require high-performance builds, extra seats, or advanced security, Netlify doesn't charge a single cent to serve static files from your domain. It has saved me so much time, money, and worry while building and maintaining our wedding website. It Just Works™</p>
  <br/>
  <p>Wondering how Netlify works under the hood? Give this Medium article a read: <a href="https://medium.com/netlify/how-netlifys-deploying-and-routing-infrastructure-works-c90adbde3b8d" rel="noopener noreferrer" target="_blank" ><i>How Netlify’s deploying and routing infrastructure works</i></a>.</p>`,
  category: "Code",
  date: "January 7, 2020",
  disableImages: true,
  images: [],
  route: "wedding-website",
  subcategory: "Wedding",
  title: "Netlify: A Love Story",
};
