"use es6";

import React, { useCallback, memo } from "react";
import styled from "styled-components";
import { ANIMATION_DURATIONS, COLORS, SCREEN_SIZES } from "../style/constants";
import useLazyImage from "../components/useLazyImage";

const StyledPhoto = styled.div`
  animation: ${({ shouldUseAnimation }) =>
    shouldUseAnimation && `fadein ${ANIMATION_DURATIONS.SLOW}s ease`};
  background-color: ${COLORS.LIGHT_PINK};
  background-image: url(${({ backgroundSrc }) => backgroundSrc});
  background-size: cover;
  cursor: pointer;
  flex-basis: calc(33vw - 3px);
  height: calc(33vw - 3px);
  margin-bottom: 3px;
  margin-right: 3px;

  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    height: calc(33vw - 28px - 30px);
    flex-basis: calc(33vw - 28px - 30px);
    margin-bottom 28px;
    margin-right 28px;
  }

  @media screen and (min-width: ${SCREEN_SIZES.LARGE}) {
    height: calc(33vw - 36px - 120px);
    flex-basis: calc(33vw - 36px - 120px);
  }
`;

const FinstaPhoto = ({ imgIdx, onClickFinstaPhoto, src }) => {
  const imgSrc = useLazyImage(src);

  const handleClick = useCallback(() => {
    onClickFinstaPhoto(imgIdx);
  }, [imgIdx, onClickFinstaPhoto]);

  return (
    <StyledPhoto
      shouldUseAnimation={Boolean(imgSrc)}
      backgroundSrc={imgSrc}
      onClick={handleClick}
    />
  );
};

export default memo(FinstaPhoto);
