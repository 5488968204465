"use es6";

import React from "react";
import styled from "styled-components";
import { COLORS, ANIMATION_DURATIONS } from "../style/constants";
import Menu from "./Menu";
import SignupButton from "./SignupButton";
import { useLocation } from "react-router-dom";

const StyledFooter = styled.div`
  align-items: center;
  background-color: ${COLORS.WHITE};
  border-top: 1px solid ${COLORS.GRAY};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: "Playfair Display", serif;
  min-height: 200px;
  margin: ${({ disableMarginTop }) =>
    disableMarginTop ? "0 30px 60px 30px" : "120px 30px 60px 30px"};
  justify-content: center;
  padding-top: 30px;
`;

const Copyright = styled.p`
  animation: slideup ${ANIMATION_DURATIONS.SLOW}s ease;
  color: ${COLORS.PINK};
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  margin-top: ${({ marginTop }) => marginTop};
  text-transform: uppercase;
`;

const Footer = ({ disableMarginTop }) => {
  const date = new Date();
  const thisYear = date.getFullYear();

  const { pathname } = useLocation();
  const disableSubscribeInFooter =
    pathname === "/signup" || pathname === "/about";

  return (
    <StyledFooter disableMarginTop={disableMarginTop}>
      <Menu color={COLORS.DARK_GRAY} isFooterMenu={true} />
      {!disableSubscribeInFooter && <SignupButton />}
      <Copyright marginTop={disableSubscribeInFooter ? "50px" : "100px"}>
        © {thisYear} Whereverist
      </Copyright>
    </StyledFooter>
  );
};

export default Footer;
