"use es6";

import React from "react";
import styled from "styled-components";
import { ANIMATION_DURATIONS, SCREEN_SIZES, COLORS } from "../style/constants";
import pin from "../assets/pin.png";
import mail from "../assets/mail.png";
import insta from "../assets/insta.png";
import { Link } from "react-router-dom";

const ICONS = [
  {
    isLocal: true,
    label: "@kutchmeifyoucan",
    link: "/kutchmeifyoucan",
    src: insta,
    useDaysSinceIG: true,
  },
  {
    label: "@thewhereverist",
    link: "https://www.pinterest.com/annakutch/",
    src: pin,
  },
  {
    label: "hello@whereverist.com",
    link: "mailto:hello@whereverist.com",
    src: mail,
  },
];

const IconContainer = styled.div`
  animation: ${({ disableAnimation }) =>
    !disableAnimation && `fadein ${ANIMATION_DURATIONS.SLOW}s ease`};
  display: ${({ shouldShowIcons }) => (shouldShowIcons ? "flex" : "none")};
  flex-direction: ${({ useColumn }) => (useColumn ? "column" : "row")};

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    `
    bottom: 60px;
    justify-content: center;
    position: fixed;
    width: 100vw;
    z-index: 4;
  `}

  @media screen and (min-width: ${SCREEN_SIZES.SMALL}) {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding-left: 21px;
    padding-right: 0;
    position: unset;
    width: unset;

    ${({ isMenuOpen }) =>
      isMenuOpen &&
      `
      bottom: 60px;
      justify-content: center;
      position: fixed;
      width: 100vw;
    `}
  }

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    animation: ${({ disableAnimation }) =>
      !disableAnimation && `slideleft ${ANIMATION_DURATIONS.SLOW}s ease`};
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 21px;
  }
`;

const StyledIcon = styled.img`
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  height: 27px;
  margin: ${({ useColumn }) => (useColumn ? "6px" : "9px")};
  width: 27px;

  @media screen and (min-width: ${SCREEN_SIZES.SMALLISH}) {
    margin: ${({ useColumn }) => (useColumn ? "9px" : "15px")};
  }
`;

const IconWithLabel = styled.a`
  align-items: center;
  display: flex;
  text-decoration: none;
  color: ${COLORS.PINK};

  &:hover {
    color: ${COLORS.DARK_PINK};
  }
`;

const IconWithLabelLink = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;
  color: ${COLORS.PINK};

  &:hover {
    color: ${COLORS.DARK_PINK};
  }
`;

const TextLink = styled(Link)`
  color: ${COLORS.PINK};

  &:hover {
    color: ${COLORS.DARK_PINK};
  }
`;

const IconLabel = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
  padding-left: 6px;
  text-transform: uppercase;

  text-decoration: ${({ useStrikethrough }) =>
    useStrikethrough ? "line-through" : "none"};
`;

const StyledIGDisclaimer = styled.div`
  color: ${COLORS.PINK};
  font-size: 12px;
  padding-left: 54px;
  padding-bottom: 12px;
`;

const DaysWithout = styled.span`
  font-family: "Roboto", sans-serif;
`;

const SocialIcons = ({
  disableAnimation,
  isFooterMenu,
  isMenuOpen,
  onClick,
  useColumn,
  useLabels,
}) => {
  const renderIcon = (icon, idx) => {
    const key = `${icon.label}-${idx}`;

    const linkProps = icon.isLocal
      ? {
          to: icon.link,
        }
      : {
          href: icon.link,
          target: "_blank",
          rel: "noopener noreferrer",
        };

    if (!useLabels) {
      const LinkComponent = icon.isLocal ? Link : "a";

      return (
        <LinkComponent key={key} {...linkProps}>
          <StyledIcon src={icon.src} useColumn={useColumn} />
        </LinkComponent>
      );
    }

    const LinkComponent = icon.isLocal ? IconWithLabelLink : IconWithLabel;

    const oct20 = new Date("10/02/2020");
    const today = new Date();
    const diffTime = Math.abs(today - oct20);
    const daysWithoutIG = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return (
      <div key={key}>
        <LinkComponent {...linkProps}>
          <StyledIcon src={icon.src} useColumn={useColumn} />
          <IconLabel useStrikethrough={icon.useDaysSinceIG}>
            {icon.label}
          </IconLabel>
        </LinkComponent>
        {icon.useDaysSinceIG && (
          <StyledIGDisclaimer>
            <DaysWithout>{daysWithoutIG}</DaysWithout> days without Instagram,{" "}
            <TextLink {...linkProps}>see photo grid</TextLink>
          </StyledIGDisclaimer>
        )}
      </div>
    );
  };

  return (
    <IconContainer
      disableAnimation={disableAnimation}
      isMenuOpen={isMenuOpen}
      useColumn={useColumn}
      shouldShowIcons={isMenuOpen || useColumn || isFooterMenu}
      onClick={onClick ? onClick : undefined}
    >
      {ICONS.map(renderIcon)}
    </IconContainer>
  );
};

export default SocialIcons;
