"use es6";

import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CategorySubheading from "../components/CategorySubheading";
import { ANIMATION_DURATIONS, COLORS, SCREEN_SIZES } from "../style/constants";
import debounce from "lodash.debounce";
import Microcopy from "./Microcopy";
import ViewGallery from "./ViewGallery";
import ShareLinks from "./ShareLinks";

const FLOATING_TITLE_WIDTH = 200;

const propTypes = {
  post: PropTypes.object.isRequired,
};

const FloatingContainer = styled.div`
  display: none;

  @media screen and (min-width: ${SCREEN_SIZES.LARGE}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: ${({ positionLeft }) => positionLeft}px;
    opacity: ${({ shouldShowFloatingTitle }) =>
      shouldShowFloatingTitle ? 1 : 0};
    position: fixed;
    transition: opacity ${ANIMATION_DURATIONS.MEDIUM}s ease;
    top: 15vh;
    width: ${FLOATING_TITLE_WIDTH}px;
  }
`;

const TitleBox = styled.div`
  background-color: ${COLORS.LIGHT_PINK};
  padding: 21px 30px 30px 30px;
`;

const Title = styled.h1`
  color: ${COLORS.DARK_GRAY};
  font-size: 30px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
  line-height: 1;
  padding-bottom: 21px;
`;

const StyledShareLinks = styled(ShareLinks)`
  padding-top: 30px;
`;

const Date = styled(Microcopy)`
  padding-bottom: 15px;
  padding-top: 15px;
`;

const getLeftPosition = (postBodyMaxWidth) => {
  const postBodyLeftPadding = 30;
  const leftColumnAvailableSpace =
    (window.innerWidth - postBodyMaxWidth) / 2 + postBodyLeftPadding;
  const floatingTitleWidth = FLOATING_TITLE_WIDTH;
  return (leftColumnAvailableSpace - floatingTitleWidth) / 2;
};

const FloatingPostTitle = ({
  numberOfImages,
  onGalleryLinkClick,
  post,
  postBodyMaxWidth,
}) => {
  const [shouldShowFloatingTitle, setShouldShowFloatingTitle] = useState(false);
  const [positionLeft, setPositionLeft] = useState(
    getLeftPosition(postBodyMaxWidth)
  );

  const handleCheckPlacement = useCallback(() => {
    if (window.innerWidth < SCREEN_SIZES.LARGE) {
      return;
    }

    const postBody = document.querySelector(
      '[data-measured-element="post-body"]'
    );

    if (!postBody) {
      return null;
    }

    const topOfPostBody = postBody.getBoundingClientRect().top;

    if (topOfPostBody <= -50) {
      // TODO LATER ANNA: prevent scrolling past post body

      if (!shouldShowFloatingTitle) {
        return setShouldShowFloatingTitle(true);
      }

      return null;
    }
    return setShouldShowFloatingTitle(false);
  }, [shouldShowFloatingTitle]);

  const handleSetLeftPosition = useCallback(() => {
    if (window.innerWidth < SCREEN_SIZES.LARGE) {
      return;
    }

    setPositionLeft(getLeftPosition(postBodyMaxWidth));
  }, [postBodyMaxWidth]);

  const debouncedSetLeftPosition = debounce(handleSetLeftPosition, 250);

  useEffect(() => {
    window.addEventListener("scroll", handleCheckPlacement);
    window.addEventListener("resize", debouncedSetLeftPosition);

    return function cleanup() {
      window.removeEventListener("scroll", handleCheckPlacement);
      window.removeEventListener("resize", debouncedSetLeftPosition);
    };
  }, [handleCheckPlacement, debouncedSetLeftPosition]);

  const renderTitleBox = useCallback(() => {
    return (
      <TitleBox>
        <Title>{post.title}</Title>
        <CategorySubheading
          color={COLORS.DARK_GRAY}
          category={post.category}
          subcategory={post.subcategory}
        />
        <Date>{post.date}</Date>
        <StyledShareLinks links={post.shareLinks} />
      </TitleBox>
    );
  }, [post]);

  if (window.innerWidth < SCREEN_SIZES.LARGE) {
    return null;
  }

  return (
    <FloatingContainer
      shouldShowFloatingTitle={shouldShowFloatingTitle}
      positionLeft={positionLeft}
    >
      {renderTitleBox()}
      {!post.disableImages && (
        <ViewGallery
          onLinkClick={onGalleryLinkClick}
          numberOfImages={numberOfImages}
        />
      )}
    </FloatingContainer>
  );
};

FloatingPostTitle.propTypes = propTypes;
export default FloatingPostTitle;
