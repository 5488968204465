"use es6";

// roofdeck
import feature from "./feature.jpg";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpg";
import img5 from "./5.jpg";

// Pictures to add: from the winter, with all the equiptment, at night

export const post = {
  backgroundImageUrl: feature,
  backgroundPosition: "center -975px",
  body: `<p>Living in the city has it's ups and downs. Living in New England even moreso. We get this short, sweet window of temperate weather before the dark, cold days of winter. It's imperative that, as New Englanders, we take full advantage of the warmth and sun while it lasts. One of my favorite ways to enjoy the nice weather is up on my roofdeck. It's the perfect getaway, just one steep staircase above the rest of the apartment. It's been a joy to have and a joy to share with others. Though this roofdeck has seen it's fair share of sunshine and visitors, there was one day and invididual that was extra special.</p>
  <p>We call my Mom's Mom "Majoy". She's got the gift of the gab – she can tell stories for days on end. And she tells them well, knowing when to add voice inflections, gestures, and jokes. Majoy has always loved to travel. She's seen the greater part of Europe in the last decade. At 80 years young, she continues booking trips abroad to explore more of the world. While Charlestown isn't quite as charming as Europe, Majoy was still excited to see my new apartment and roofdeck. The challenge? There are three flights of stairs up to our apartment and then a last, quite steep flight up to the roof. Getting up and down all four flights is no easy task.</p>
`,
  body2: `<p>Since we moved into the apartment in September and didn't furnish it until the following April, we missed our window to have Majoy over to enjoy the roof until then. The weather finally started improving in late May, so we scheduled a Saturday that worked for the whole family. We were close to roofdeck capacity when Majoy, my parents, siblings, aunt, uncle, and cousins all showed up. We had spotters in front of and behind Majoy as she fearlessly made her way up all four flights of stairs, but she didn't need our help. We had a perfect BBQ filled with good music, funny stories, and way too much food. By the time we were done, it was dark outside and, to navigate the unlit stairs on the way down, we gave Majoy a headlamp. There was another flawless execution on the descent, but it was a sight to behold. Living in the city truly has it's ups and downs.</p>
  `,
  category: "Living",
  date: "February 22, 2020",
  images: [img1, img2, img3, img4, img5],
  route: "roofdeck",
  subcategory: "Outdoor Furniture",
  title: "Roofdeck",
};
