"use es6";

// trudy closet
import feature from "./feature.jpg";
import img1 from "./1.jpeg";
import img2 from "./2.jpg";
import img3 from "./3.jpeg";
import img4 from "./4.jpg";
import img5 from "./5.jpg";
import img6 from "./6.jpg";
import img7 from "./7.jpg";
import img8 from "./8.jpeg";
import img9 from "./9.jpeg";
import img10 from "./10.jpeg";
import img11 from "./11.jpeg";
import img12 from "./12.jpeg";
import img13 from "./13.jpeg";
import img14 from "./14.jpeg";
import img15 from "./15.jpg";

export const post = {
  backgroundImageUrl: feature,
  body: `<p>When we postponed our wedding, after months of back-and-forth due to covid-19, we were bummed. Really bummed. To distract us, we kept busy with DIY projects around our new house. It didn't take long for us to learn how, even the simplest projects, take a lot of time and effort. Looking back, it's surprising to think we knocked out replacing our bathroom vanity, refinishing the tub, building a fence along the backyard, installing a fan in our bedroom, repainting a few rooms, adding a French door in the kitchen, and tiling the mudroom floor, all in the first 2 months of living here.</p>
  <p>Yes, we were busy.</p>
  <p>We started to learn our limits in terms of skills, tools, and financing. One project we especially wanted to tackle, but needed help with in those departments was converting our bedroom nook into a walk-in closet. 
  </p>`,
  body2: `<p>The original closet left a lot to be desired. There was a decent amount of space, but most was unreachable. After pricing out a few options, we decided to add cabintry to both walls in the nook, close in the space a little more with an arch opening, and sneak in a vanity on the left side, since we didn't want to overlap or move the existing window.</p>
  <p>By far, the most exciting step was demolishing the existing wall. Our contractor let Luke and I take turns at it – it was a real Joanna Gaines moment. Piece by piece, the closet come to life and the end result is better than we imagined. A perfect use of space and a great way to reroute wedding funds. </p>
  <br/>
  <p><i>Many thanks to Rafael and <a href="https://www.thumbtack.com/ma/rowley/carpenters/rfs-carpentry-services/service/366487888853336065?project_pk=406928371733602310" target="_blank">RFS Carpentry Services</a> for building out our dream closet</i></p>
  `,
  category: "Living",
  date: "November 14, 2020",
  images: [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
  ],
  route: "trudy-closet",
  subcategory: "Renovation",
  title: "Home Improvement",
};
