"use es6";

// zermatt
import feature from "./feature.jpg";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpeg";
import img5 from "./5.jpg";
import img6 from "./6.jpg";
import img7 from "./7.jpg";
import img8 from "./8.jpeg";
import img9 from "./9.jpg";
import img10 from "./10.jpg";
import img11 from "./11.jpeg";
import img12 from "./12.jpg";
import img13 from "./13.jpeg";
import img14 from "./14.jpg";
import img15 from "./15.jpeg";
import img16 from "./16.jpg";
import img17 from "./17.jpg";
import img18 from "./18.jpeg";
import img19 from "./19.jpeg";

export const post = {
  backgroundImageUrl: feature,
  body: `<p>There's always one in the family or in the friend group. The planner. They manage to find a time that works for everyones' schedules and many, thankless hours building an intinerary for the occasion. For my family, our planner is my eldest sister, Allie. She's a fashion buyer by trade and planning is her passion. As she approached her 30th birthday, we knew she would plan something memory-worthy. All we had to do was say yes. My Dad was also turning 60th that year, so we dubbed it the "30/60 Party". My sister drew from her bucket list in choosing our venue: the Swiss Alps.</p>
  <p>This is the same sister that's known for such hits as Martha's Vineyard 2017, Park City 2018, and Oktoberfest 2019. Not only does she pick the location, but she spends hours scouring online reviews to make reservations and internalize recommendations to help us experience the place to it's fullest. The Zermatt trip was no different. There was just one small hiccup: our flight was cancelled.</p>`,
  body2: `<p>Needless to say, no one planned on the flight being cancelled. Not even the airline. There was a mechanical problem that caused the three-hundred-something passengers to be stranded in Boston until the next scheduled flight three days later. Six hours and six phone calls after our scheduled departure, we secured tickets on a red eye the following day. We held our breath until the plane was off the ground in Boston and on the ground in Zurich.</p>
  <p>Upon arriving to our Chalet in Zermat, we noticed the jagged peak of the larger-than-life Matternhorn from our window. Our excitement for the week ahead was at an all time high. The week that followed was filled with dreamy, scenic gondola rides, fine French cuisine, many-a-stein of beer, and an unmatched apres scene. The trip was incredible in so many senses. We took in mountain views and ski trails that extended for miles, learned that my sister was expecting a healthy, baby girl, and danced for hours at Paperella Pub. We had the best lunch of our lives on the mountain at the Michelin-rated Chez Vrony. The food was as good as the skiing which was as good as the company. The 30/60 trip far exceeded our expectations and left us wanting more. I have a funny feeling we will be back to the alps.</p>`,
  category: "Travel",
  date: "February 29, 2020",
  images: [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
  ],
  route: "zermatt",
  subcategory: "Skiing",
  title: "Zermatt",
};
