"use es6";

// house plants
import feature from "./feature.jpeg";
import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";

export const post = {
  backgroundImageUrl: feature,
  body: `<p>Having a plant is kind of like having a dog. You have to make sure it has enough water, you need to take it out when it gets unruly, and you need to like the thing to make it work. I hit critical mass four or five plants ago and am happy to say I haven't killed one in quite some time. I know my limits at this point – I won't make the mistake of buying another Calathea nor am I prepared enough to spend hundreds on a rare species of Anthurium. I know some of the binomial nomenclature, but not all. Like any (plant) parent, I have my favorites: the Brasil philodendron and the Monstera deliciosa.</p>
  <p>Trailing plants always intrigued me. Some of these plants stretch across entire rooms... but how? I was destined to find out if I could grow one like that. To my surprise, I found out most trailing plants aren't already trailing when you buy them. I picked up my Brasil Philodendron with ten or so leaves and placed it on my shelf. I was a few plants deep at this time, and knew to only water it once a week. I kept it up for about a year, but the thing wasn't going far. It wasn't until I moved and repotted it that the thing exploded. Repotting is your friend. Giving the plant fresh, nutrient-full soil will help it thrive. The philodendron went from the mantle all the way to the floor – my marker of success.</p>`,
  body2: `<p>Are you even a millenial if you don't own a Monstera? Catalogs of West Elm and Instagram feeds alike are plagued with the thing. And for good reason. It has these large, lucious, aesthetically pleasing leaves. It's unwieldy in a lovable way. One day when driving through Cambridge, I spotted one in a shop window. It was in better shape than many I'd seen, fairly mature, and priced reasonably. Read: begging to be bought. Over a year later, it's still thriving. In the warmer months, it grows new leaves like crazy. This plant has made me feel very much like I've made it as a Level 2 plant owner.</p>
  <p>Looking to start a collection of your own? In the early stages, stick to the classics. Snake plants, Pothos, and Philodendrons. If you can keep those alive, move on up to ZZ Plants, Aglaonemas (Chinese evergreen), Pilea peperomioides (money plant), and Monsteras. Remember, feel the soil before you water it. Fertilize your plants as needed, make sure they're getting enough light, and repot them at least once a year. Before you buy plants, make sure they're bug-free. If you find bugs, you can likely cure them with Horticultural oil and by repotting it. When in doubt, Google it!</p>
  <br />
  <p><i>In loving memory of the plants from which I learned many of these lessons and to the Chinese Evergreen that <a href="/posts/raleigh">Raleigh</a> ate.</i></p>`,
  category: "Living",
  date: "April 25, 2020",
  images: [img1, img2, img3],
  route: "house-plants",
  subcategory: "Garden",
  title: "House Plants",
};
