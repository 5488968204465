"use es6";
import React from "react";
import Page from "../components/Page";
import { POSTS } from "../content/PostData";
import Footer from "../components/Footer";
import useLazyImage from "../components/useLazyImage";
import LoadingIndicator from "../components/LoadingIndicator";
import PostPreviewGrid from "../components/PostPreviewGrid";
import isLoading from "../helpers/isLoading";
import usePageTitle from "../components/usePageTitle";

const Home = () => {
  usePageTitle("Whereverist");

  const featuredPhotoUrl = useLazyImage(POSTS[0].backgroundImageUrl);
  const secondaryPhoto1Url = useLazyImage(POSTS[1].backgroundImageUrl);
  const secondaryPhoto2Url = useLazyImage(POSTS[2].backgroundImageUrl);

  if (
    [featuredPhotoUrl, secondaryPhoto1Url, secondaryPhoto2Url].every(isLoading)
  ) {
    return <LoadingIndicator />;
  }

  const featuredPost = POSTS[0];

  return (
    <Page disablePadding={true} shouldUseFullHeight={true}>
      <PostPreviewGrid
        featuredPost={featuredPost}
        featuredPhotoUrl={featuredPhotoUrl}
      />
      <Footer />
    </Page>
  );
};

export default Home;
